import { Box, Grid, Pagination, Stack, Typography } from "@mui/material";
import StockCard from "./stockCard";
import { getStockDetail } from "./stockApi";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

import { useEffect } from "react";
import { useState } from "react";
import { getData } from "./stockCustomApiHandler";

const Stocks = ({gridSize}) => {
  const [stocks, setStocks] = useState();
  const stockPayload = {
    page: 1,
    pageSize: 36,
  };

  const [pagination, setPagination] = useState({
    page: 1,
    total: 1,
    records: 0,
  });

  const handleChange = (event, value) => {
    getData({ ...stockPayload, page: value });
    setPagination({
      ...pagination,
      page: value,
    });
  };

  useEffect(() => {
    getData(stockPayload, getStockDetail, setStocks, setPagination, pagination);
  }, []);
  return (
    <Grid
      container
      sx={{
        p: "20px",
        display: "flex",
      }}
      spacing={2}
    >
      






      <Grid xs={12} sx={{textAlign:'right'}}>
      {gridSize && (
            <FileDownloadIcon
            sx={{
              ml: "10px",
              mr: "0px",
              height: "1.4375em",
              cursor: "pointer"
              // m: "16.5px 14px",
            }}/>
        )}
      </Grid>
      <Grid item md={gridSize?gridSize.sm:5.5} container spacing={2}
        sx={{ display: "flex", flexDirection: "column", height: "80vh" }}

      >
           <Box   sx={{
              m: 1,
              width: "50%",
               
            }}
            >


         <Grid container>
         <Grid xs={5 } sx={{ display: "flex" }}>Item Name
          </Grid>

         <Grid xs={4} sx={{ display: "flex" }}>
          Alert Value
          </Grid>

         <Grid xs={3 } sx={{ display: "flex" }}>
          Available
          </Grid>
          
          </Grid>
         </Box>

        {stocks?.map((item, index) => {
          return (
            <Box   sx={{
              m: 1,
              width: "50%",
              boxShadow: item.totalItemQuantity < item?.inventoryAlarmValue ? "0 0 10px 2px red" : "none",
              backgroundColor: item.totalItemQuantity < item?.inventoryAlarmValue ? "red" : "initial",
              color: item.totalItemQuantity < item?.inventoryAlarmValue ? "white" : "initial",
              animation: item.totalItemQuantity < item?.inventoryAlarmValue ? "blinking 1.5s infinite" : "none",
              "@keyframes blinking": {
                "0%": { boxShadow: "0 0 10px 2px red", backgroundColor: "red", color: "white" },
                "50%": { boxShadow: "0 0 10px 2px rgba(255, 0, 0, 0)", backgroundColor: "transparent", color: "red" },
                "100%": { boxShadow: "0 0 10px 2px red", backgroundColor: "red", color: "white" }
              }
            }}>
              <StockCard item={item} />
            </Box>
          );
        })}
        {/* {stocks?.map((item, index) => {
          return (
            <Box sx={{ m: 1, width: "50%" }}>
              <StockCard item={item} />
            </Box>
          );
        })}
        {stocks?.map((item, index) => {
          return (
            <Box sx={{ m: 1, width: "50%" }}>
              <StockCard item={item} />
            </Box>
          );
        })}
        {stocks?.map((item, index) => {
          return (
            <Box sx={{ m: 1, width: "50%" }}>
              <StockCard item={item} />
            </Box>
          );
        })}
        {stocks?.map((item, index) => {
          return (
            <Box sx={{ m: 1, width: "50%" }}>
              <StockCard item={item} />
            </Box>
          );
        })}
        {stocks?.map((item, index) => {
          return (
            <Box sx={{ m: 1, width: "50%" }}>
              <StockCard item={item} />
            </Box>
          );
        })}
        {stocks?.map((item, index) => {
          return (
            <Box sx={{ m: 1, width: "50%" }}>
              <StockCard item={item} />
            </Box>
          );
        })}
 */}

        {/* {stocks?.map((item, index) => {
          return (
            <Grid item xs={12} md={4} lg={3} sx={{ mt: "10px" }}>
              <StockCard item={item} />
            </Grid>
          );
        })}
        {stocks?.map((item, index) => {
          return (
            <Grid item xs={12} md={4} lg={3} sx={{ mt: "10px" }}>
              <StockCard item={item} />
            </Grid>
          );
        })} */}
        {/* {stocks?.map((item, index) => {
          return (
            <Grid item xs={12} md={4} lg={3} sx={{ mt: "10px" }}>
              <StockCard item={item} />
            </Grid>
          );
        })}
        {stocks?.map((item, index) => {
          return (
            <Grid item xs={12} md={4} lg={3} sx={{ mt: "10px" }}>
              <StockCard item={item} />
            </Grid>
          );
        })} */}
       
      </Grid>

      <Grid container sx={{ pt: "10px" }}>
        <Grid item xs={4}>
          <Typography
            color="primary"
            sx={{ display: "flex", alignItems: "center" }}
          >
            <strong>Total Items: &nbsp;</strong>
            <strong> {pagination.records}&nbsp;</strong>
          </Typography>
        </Grid>
        <Grid
          item
          xs={4}
          sx={{
            // bgcolor: "red",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {/* <Stack direction="row" justifyContent="space-around" sx={{ mt: 2 }}> */}
          <Pagination
            count={pagination.total}
            page={pagination.page}
            onChange={handleChange}
          />
          {/* </Stack> */}
        </Grid>
        <Grid item xs={4}></Grid>
      </Grid>

    </Grid>
  );
};

export default Stocks;
