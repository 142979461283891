import {
  Box,
  Card,
  CardContent,
  Grid,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
export default function ExpenseItemCard({ item, onClick ,width}) {
  console.log("item ", item);
  return (
    <Card
      sx={{
        mt: 1,
        // mr: 2,
        cursor: "pointer",
        ":hover": {
          bgcolor: "#2892ba17",
        },
      }}
      onClick={() => onClick(item)}
    >
      <CardContent sx={{ padding: "0.5px 10px !important" }}>
        <Grid container>
          <Grid xs={width?width:8} sx={{ display: "flex" }}>
            <Typography
              color="primary"
              sx={{
                overflow: "Hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                "-webkit-line-clamp": "1",
                "-webkit-box-orient": "vertical",
                // fontWeight: 600,
                // color: "blac",
              }}
            >
              {(width)?item?.name:item?.category}
            </Typography>
          </Grid>
         {!width && <Grid xs={4} sx={{ display: "flex", alignItems: "center" }}>
            <CurrencyRupeeIcon fontSize="10px" />

            <Typography
              sx={{
                overflow: "Hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                "-webkit-line-clamp": "1",
                "-webkit-box-orient": "vertical",
                // fontWeight: 600,
              }}
            >
              {item?.amount?.toFixed(2)}
            </Typography>
          </Grid>}
        </Grid>
      </CardContent>
    </Card>
  );
}
