export const routePath = {
  ROOT: "/*",
  LANDINGPAGE: "/home",
  SERVICES:"/services",
  REVIEWANDRATING:"/ReviewAndRating/:id",
  ADVERTICE:"/advertice",

  DASHBOARD: "/dashboard",
  EMPLOYEEINFO:"/employeeinfo",
  EMPLOYEEPAYROLL:"/employeepayroll",
  
  
  LANDINGPAGEHOME: "/homepage",
  SERVICESLIST:"/ServicesList",
  APPOINMENT:"/appoinment",
  PAYMENTSTRUCT:"/paymentstruct",
  
  APPOINMENTDASHBOARD:"/appoinmentdashboard",

  REGISTRATION:"/REGISTRATION",
  OTPAPPOINMENT:'/otpappoinment',
  OTP:"/otp",
  SETMPIN:"/setMpin",
  USERPROFILE:"/userprofile",
  LANDINGPAGERESTORENT: "/restorant",
  INVENTORY:'/inventory',
  FAV:'/fav',
  LANDINGPAGECOUNTERTOKEN: "/token",
  
  DETAIL:'/detail',
  LANDINGPAGEMONTHLYBILL: "/monthlybill",
  LANDINGPAGEFRANCHISE: "/franchise",
  LOGIN: "/login",
  ADVANCEPAYMENT:"/advancepayment",
  SHARESESSAGE:"/sharemessage",
  COUNTERSELL: "/countersell",
  COUNTERSELLBILLING: "/countersellbilling",
  MYPROFILE: "/myprofile",
  
  MYBUSSINESS: "/mybusiness",
  
  TABLEMASTER: "/tablemaster",
  CUSTOMERINFORMATION:"/customerinformation",
  UPDATEBILLS: "/updatebills",
  ITEMSERVICES: "/item-services",
  TABLESECTION: "/table-section",
  ITEMCATEGORIES: "/item-categories",
  ASSOCIATEDUSERS: "/associated-users",
  PAYMENTRECEIPT: "/payament-receipt",
  OUTSTANDINGREPORT: "/outstanding-report",
  MOBILEPAGEMENU: "/mobil-emenu-Page",
  SMBACCOUNTREACHARGE: "/smb-account-reacharge",
  ITEMWISESALE: "/item-wise-sale",
  REPORTS: "/reports",
  STOCKS: "/stocks",
  PURCHASE: "/purchase",
  EXPENCE: "/expence",
  HELPSUPPORT: "/help-support",
  RESETMPIN: "/reset-mpin",
  SETTING: "/setting",
  RATECHANGE: "/rate-change",
  CASHCOUNTERSALE: "/cash-counter-sale",
  KYCPROCESS: "/kyc-process",
  PRIVACYPOLICY:"/privacyPolicy",
  TERMSOFSERVICE:"/termsOfService",

  ADDOFFER:"/addOffers",
  GETOFFERBYBILLER:"/getUserOffer"
};
