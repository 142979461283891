import ContactPageIcon from "@mui/icons-material/ContactPage";
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import { useLocation, useNavigate } from "react-router";
import logo from "../../assets/img/logo.svg";
import {
  Box,
  Button,
  Drawer,
  Grid,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useState } from "react";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { routePath } from "../../constants/routePath";
import { setchangeRoll, setNavBarInputFields, setUserDetail } from "../../app/store/reducer";
import { useDispatch, useSelector } from "react-redux";
import SearchIcon from "@mui/icons-material/Search";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import FavoriteIcon from '@mui/icons-material/Favorite';
import { setSession } from "../../utils/commonUtils";
export const NavBar = (prop) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const navBarInputFields = useSelector(
    (state) => state.reducer.navBarInputFields
  );

  const [open, setOpen] = useState(false);
  const userDetail = useSelector((state) => state.reducer.userDetail);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };
  return (
    <Box
      sx={{
        backgroundColor: "#fff",
        boxShadow: "0 0 1rem 0px rgb(0 0 0 / 11%) !important",
      }}
    >
      <Grid
        container
        spacing={2}
        justifyContent="space-between"
        alignItems="center"
        px="20px"
      >
        <Grid item xs={9} sm={4} display="flex" justifyContent="start">
          <Box
            sx={{
              display: "flex",
              gap: 2,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              width="65px"
              component="img"
              src={logo}
              alt="logo"
              onClick={() => navigate(routePath.LANDINGPAGE)}
              sx={{ cursor: "pointer" }}
            />
            <Typography
            sx={{display:{sm:'block', xs:'none'}}}
              className="kanit"
              color={"secondary"}
              fontSize={"25px"}
              fontWeight={"500"}
            >
              Elegant Prime
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={2} sx={{ display: { md: "block", xs: "none" } }}>
          <TextField
            placeholder="Location"
            sx={{
              "& .MuiInputBase-input": {
                padding: "7.5px 10px !important",
                fontFamily: "Kanit",
              },
            }}
            value={prop?.navBarInputFields?.location}
            onClick={() => {
              prop.setLocationModal();
            }}
            fullWidth
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={3.5}
          sx={{ display: { md: "block", xs: "none" } }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <TextField
              placeholder="Search Services / Products"
              fullWidth
              sx={{
                "& .MuiInputBase-input": {
                  padding: "7.5px 10px !important",
                  fontFamily: "Kanit",
                },
              }}
              value={navBarInputFields.bussiness}
              onChange={(e) =>
                dispatch(setNavBarInputFields({ bussiness: e.target.value }))
              }
            />
            {/* {location.pathname === "/home" && ( */}
              <Button
                variant="contained"
                color="primary"
                sx={{
                  backgroundColor: "#3695B6 !important",
                  borderRadius: "25px",
                  padding:"6px 30px",
                  mx: 2,
                }}
                onClick={() => {
                  if (navBarInputFields.bussiness?.trim() != "") {
                    navigate(routePath.SERVICESLIST);
                  }
                }}
                startIcon={<SearchIcon />}
              >
                Search
              </Button>
            {/* )} */}
          </Box>
        </Grid>
        {/* <Grid
            item
            xs={12}
            sm={2}
            display="flex"
            alignItems="center"
            justifyContent="end"
          >
            <Button
              onClick={() => navigate("/login")}
              className="mulish"
              variant="contained"
              color="primary"
              sx={{
                display: "flex",
                alignItems: "center",
                backgroundColor: "#3695B6 !important",
                borderRadius: "25px",
              }}
            >
              <ContactPageIcon sx={{ mr: 1 }} />
              Login
            </Button>
          </Grid> */}
        <Grid
          item
          xs={3}
          sm={2.5}
          display="flex"
          alignItems="center"
          justifyContent="end"
          gap={1}
        >
          <Button
            sx={{ display: { xs: "flex", md: "none" }, px:{md:2,xs:5} }}
            variant="contained"
            color="primary"
            endIcon={<MenuIcon />}
            onClick={toggleDrawer(true)}
          >
            Menu
          </Button>
          
          {
            userDetail?.appMenu=="Biller" && (
              <Tooltip title="Change Role">
              <Button
            sx={{ px:{md:2,xs:5} }}
            variant="contained"
            color="primary"
            onClick={()=>{
              // navigate(routePath.FAV)
              dispatch(setchangeRoll("dashboard"))

              navigate(routePath.ADVERTICE)
              
            }}
          >
            <SwapHorizIcon/>  
          </Button>
          </Tooltip>
            )
          }
          <Tooltip title="Add To Favorite">
          <Button
            sx={{ px:{md:2,xs:5} }}
            variant="contained"
            color="primary"
            onClick={()=>navigate(routePath.FAV)}
          >
            <FavoriteIcon />
          </Button>
          </Tooltip>
          {location.pathname !== routePath.USERPROFILE && (
            <Button
              sx={{borderRadius:'50px',fontSize:'12px', }}
              variant="contained"
              color="primary"
              endIcon={<AccountCircleIcon />}
              onClick={async() => {
                console.log("userDetail ", userDetail);
               await setSession("nextPage",'USERPROFILE')
                if (!userDetail || Object.keys(userDetail).length == 0) {
                  navigate(routePath.LOGIN);
                } else {
                  navigate(routePath.USERPROFILE);
                }
                toggleDrawer(true);
              }}
            >
              {userDetail.name ? userDetail?.name : "Profile"}
            </Button>
          )}
           {location.pathname === routePath.USERPROFILE && (
            <Button
              sx={{ display: "flex" }}
              variant="contained"
              color="primary"
              endIcon={<AccountCircleIcon />}
              onClick={async() => {
                console.log("userDetail ", userDetail);
                // if (!userDetail || Object.keys(userDetail).length == 0) {
                //   navigate(routePath.LOGIN);
                // } else {
                //   navigate(routePath.USERPROFILE);
                // }
                await sessionStorage.removeItem("smbToken");
                await sessionStorage.removeItem("eleGentUser");
                navigate(routePath.LOGIN)
                dispatch(setUserDetail({}));
                // toggleDrawer(true);
              }}
            >
              

              Log out
            </Button>
          )}
        </Grid>
      </Grid>
      <Drawer anchor="top" open={open} onClose={toggleDrawer(false)}>
        <Box display={"flex"} flexDirection={"column"} gap={3} p={3}>
          <TextField
            placeholder="Location"
            sx={{
              "& .MuiInputBase-input": {
                padding: "7.5px 10px !important",
                fontFamily: "Kanit",
              },
            }}
            value={prop?.navBarInputFields?.location}
            onClick={() => {
              prop.setLocationModal();
            }}
            fullWidth
          />
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <TextField
              placeholder="Search Services / Products"
              fullWidth
              sx={{
                "& .MuiInputBase-input": {
                  padding: "7.5px 10px !important",
                  fontFamily: "Kanit",
                },
              }}
              value={navBarInputFields.bussiness}
              onChange={(e) =>
                dispatch(setNavBarInputFields({ bussiness: e.target.value }))
              }
            />
            {location.pathname === "/home" && (
              <Button
                variant="contained"
                color="primary"
                sx={{
                  backgroundColor: "#3695B6 !important",
                  borderRadius: "25px",
                  mx: 2,
                }}
                onClick={() => {
                  if (navBarInputFields.bussiness?.trim() != "") {
                    navigate(routePath.SERVICESLIST);
                  }
                }}
               startIcon={<SearchIcon />}
              >
                Search
              </Button>
            )}
          </Box>
        </Box>
        <Button
          variant="contained"
          color="primary"
          endIcon={<CloseIcon />}
          onClick={toggleDrawer(false)}
          sx={{ borderRadius: "0" }}
        >
          Close
        </Button>
      </Drawer>
    </Box>
  );
};
