
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { Box } from '@mui/material';
import StarHalfIcon from '@mui/icons-material/StarHalf';
export const  Rating=({ratting,setRating,selectedColor})=>{
    console.log("ratting-parseInt(ratting) ",ratting," ",parseInt(ratting)," ",ratting>parseInt(ratting))
    return (
        <>
        <Box>
        {
                Array.from({ length: 5 }, (_, index) => {
                    if((ratting>parseInt(ratting)) && index  == parseInt(ratting)){
                        return <StarHalfIcon sx={{
                            fontSize:{md:'20px',lg:'25px'},
                            color:selectedColor?selectedColor:""
                        }} key={index} onClick={() => {
                             
                        }} />
                    }
                    if (index+1  <=ratting) {
                        return <StarIcon sx={{
                            fontSize:{md:'20px',lg:'25px'},
                            color:selectedColor?selectedColor:""
                        }} key={index} onClick={() => {
                            if(setRating){
                            setRating(index + 1)
                            }
                        }} />

                    }
                 
                    return < StarBorderIcon sx={{  fontSize:{md:'20px',lg:'25px'},}} key={index}
                        onClick={() => {
                            if(setRating){ 
                            setRating(index + 1)
                            }
                        }} />

                })
            }
            </Box>
        </>
    )
}