import { Box, Button, DialogTitle, FormControl, FormControlLabel, Grid, Modal, Radio, RadioGroup, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import PoprchModal from "./popSearch";
import { TextFieldSearch } from "../../common/fieldText";
import { addSalaryComponent, addSalaryIncentive, updateSalaryComponent } from "./paymentApi";
import { validation, validation_componetlary, validation_dedcution } from "./validation";
import { swalAlertPopProps } from "../../utils/commonUtils";
import Swal from "sweetalert2";

const PaymentModal = ({ formda,setFormda, open, setOpen, callback }) => {
  
const [searchModal,setSearchModal]=useState(false)

  const [cardValue,setCardValue]=useState(null);
  const [radioType,setRadioType]=useState("amount")

  const handleChange = (e) => {
    const { name, value } = e.target;
    // console.log()
    if (typeof value == "object") {
      setFormda({
        ...formda,
        [name]: value[0],
      });
    } else {
      setFormda({
        ...formda,
        [name]: value,
      });
    }
  };
  const [search,setSearchvalue]=useState("")

  useEffect(()=>{
    if(open==false){

      setFormda(
        { 
        customerName: "",
    
      amount: "",
      percentage: "",
      employeeId: ""
    })
      setSearchvalue("")
    }
    if(open==true){
      if(formda.percentage!="0"){
        setRadioType("percentage")
      }
      else{
        setRadioType("amount")
      }
    }
  },[open])
  const gender = (e) => {
    // setFormda((prev) => {
    //   let temp = { ...prev };
    //   if (e.target) {
    //     temp.gender = e.target.value;
    //   } else {
    //     temp.gender = e;
    //   }
    //   return { ...temp };
    // });

  };  
useEffect(()=>{
if(search==false){
  setSearchvalue("")

}
},[search])
  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          background: "white",

          width: "52%",
          overflowY: "auto",
          zIndex: "99999",
          // height: "90vh",
          position: "absolute",
          left: "25%",
          top: "10%",
        }}
      >
        <Grid container spacing={2} 
        sx={{padding:'20px'}}>
          <Grid item xs={12} sm={12}>
          <DialogTitle
        color="primary"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          m: 0,
          p: "3px",
        }}
      >
        Component Salary{" "}
        <CloseIcon
          sx={{ float: "right", cursor: "pointer" }}
          onClick={() => setOpen(false)}
        />
      </DialogTitle>
            <FormControl fullWidth margin="normal">
              {!formda?.id && <TextFieldSearch label={"Customer Name"} search={search} setOpen={setSearchModal}/>}
              

              {/* <TextField
                label="Customer Name"
                name="customerName"
                value={formda.customerName}
                onChange={handleChange}
                required
                InputLabelProps={{ className: "mulish" }}
                InputProps={{ classes: { input: "mulish" } }}
              /> */}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12}>
            <FormControl fullWidth margin="normal">
              <TextField
                label="Component Salary Name"
                name="componentName"
                type="email"
                value={formda.componentName}
                onChange={handleChange}
                required
                InputLabelProps={{ className: "mulish" }}
                InputProps={{ classes: { input: "mulish" } }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography>Amount Type</Typography>
            <FormControl fullWidth margin="normal">
            <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                    >
                      <FormControlLabel
                        value="amount"
                        control={<Radio />}
                        checked={radioType == "amount"}
                        label="Amount"
                        className="mulish"
                        onClick={(e)=>{
                          setRadioType(e.target.value)
                        }}
                      />
                      <FormControlLabel
                        value="percentage"
                        control={<Radio />}
                        checked={radioType == "percentage"}
                        label=" Percentage"
                        className="mulish"
                        onClick={(e)=>{
                          setRadioType(e.target.value)
                        }}
                      />
                     
                    </RadioGroup>
            </FormControl>
          </Grid>
        {radioType=="amount" &&  <Grid item xs={12} sm={12}>
            <FormControl fullWidth margin="normal">
              <TextField
                label="Amount"
                name="amount"
                value={formda.amount}
                onChange={handleChange}
                required
                InputLabelProps={{ className: "mulish" }}
                InputProps={{ classes: { input: "mulish" } }}
              />
            </FormControl>
          </Grid>}
          {radioType!=="amount" &&  <Grid item xs={12} sm={12}>
            <FormControl fullWidth margin="normal">
              <TextField
                label="Percentage"
                name="percentage"
                value={formda.percentage}
                onChange={handleChange}
                required
                InputLabelProps={{ className: "mulish" }}
                InputProps={{ classes: { input: "mulish" } }}
              />
            </FormControl>
          </Grid>}
          <Grid
                  item
                  xs={12}
                  sx={{ display: "flex", justifyContent: "end" }}
                >
                  <Button
                    // type="submit"
                        onClick={()=>{
                          console.log("addSalaryIncentive",formda)
                          let dataTemp={...formda}
                          dataTemp.percentage=dataTemp.percentage==""?"0":dataTemp.percentage
                          dataTemp.amount=dataTemp.amount==""?"0":dataTemp.amount
                          dataTemp.percentage=parseFloat(dataTemp.percentage)
                          dataTemp.amount=parseFloat(dataTemp.amount)
                          let valid=validation_componetlary(dataTemp,radioType)
                          if(!valid.status){
                            Swal.fire({
                              text: valid.text,
                              icon: "warning",
                              ...swalAlertPopProps,
                            });
                            setOpen(false)
                            return
                          }
                          if(dataTemp?.id){
                            updateSalaryComponent(dataTemp,()=>{
                              setOpen(false)
                              callback()
                              Swal.fire({
                                text: "Update Successfull",
                                icon: "success",
                                ...swalAlertPopProps,
                              });
                            })
                          }
                          else{
                            addSalaryComponent(dataTemp,()=>{
                              setOpen(false)
                              callback()
                              Swal.fire({
                                text: "add Successfull",
                                icon: "success",
                                ...swalAlertPopProps,
                              });
                            })
                          }
                          
                          
                        }}
                    variant="contained"
                    color="primary"
                    sx={{
                      backgroundColor: "#3695B6",
                      padding: "14px",
                      fontSize: "16px",
                    }}
                  >
                    Update 
                  </Button>
                </Grid>
        </Grid>
        <PoprchModal   cardValue={cardValue}  setCardValue={(data)=>{
            setSearchvalue(data?.name)
            setCardValue(data)
            setFormda({...formda,employeeId:data.id})

            }} callback={()=>{}} searchModal={searchModal} setSearchModal={setSearchModal} />
      </Box>
      
    </Modal>
  );
};

export default PaymentModal;
