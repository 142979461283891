import { Box, Fab, Grid, Tooltip, Typography } from "@mui/material";
import { useNavigate } from "react-router";

import { useEffect, useState } from "react";

import { setUserDetail } from "../app/store/reducer";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router/dist";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import PersonIcon from "@mui/icons-material/Person";
import StorefrontIcon from "@mui/icons-material/Storefront";
import TableBarIcon from "@mui/icons-material/TableBar";
import TableRestaurantIcon from "@mui/icons-material/TableRestaurant";
import SearchIcon from "@mui/icons-material/Search";
import HandshakeIcon from "@mui/icons-material/Handshake";
import DashboardIcon from "@mui/icons-material/Dashboard";
import CurrencyRupeeOutlinedIcon from "@mui/icons-material/CurrencyRupeeOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import HeadsetMicOutlinedIcon from "@mui/icons-material/HeadsetMicOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import { routePath } from "../constants/routePath";
import LogOutPop from "./logoutPop";                                                

export default function BussinessMangment() {
  const navigate = useNavigate();
  const headerMenu = useSelector((state) => state.reducer.headerMenu);
  const [header1, setHeader1] = useState([]);
  const [header2, setHeader2] = useState([]);

  // const arrN = (urlHeaderMenu) => {
  //   let arr1 = [...header1];

  //   let arr2 = [...header2];

  //   if (arr1.length == 0) {
  //     urlHeaderMenu.forEach((element, index) => {
  //       if (index % 2 == 0) {
  //         arr1.push(element);
  //       } else {
  //         arr2.push(element);
  //       }
  //       setHeader1(arr1);
  //       setHeader2(arr2);
  //     });
  //   }
  // };
  const iconsObj = {
    PersonIcon: PersonIcon,
    StorefrontIcon: StorefrontIcon,
    TableBarIcon: TableBarIcon,
    TableRestaurantIcon: TableRestaurantIcon,
    SearchIcon: SearchIcon,
    HandshakeIcon: HandshakeIcon,
    DashboardIcon: DashboardIcon,
    CurrencyRupeeOutlinedIcon: CurrencyRupeeOutlinedIcon,
    SettingsOutlinedIcon: SettingsOutlinedIcon,
    LockOutlinedIcon: LockOutlinedIcon,
    HeadsetMicOutlinedIcon: HeadsetMicOutlinedIcon,
    ShareOutlinedIcon: ShareOutlinedIcon,
    LogoutOutlinedIcon: PowerSettingsNewIcon,
  };
  const sideBarMenu = useSelector((state) => state.reducer.sideBarMenu);
  const bussinessMangementMenu = useSelector((state) => state.reducer.bussinessMangementMenu);
  const dispatch = useDispatch();

  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(-1);
  useEffect(() => {
    const indexRoute3 = bussinessMangementMenu.findIndex(
      (it) => it.route === location.pathname
    );

    setCurrentPage(indexRoute3);
  }, [location.pathname]);

  return (
    <Grid
      container
      spacing={0}
      sx={{
        background: "#3695B6de",
        overflowY: "auto",
        // maxHeight: "calc(100vh - 60px)",
        maxHeight: {
          xs: "100vh",
          // sm: "calc(100vh - 60px)",
          // sm:
        },
        scrollbarWidth: "thin",
        msOverflowStyle: "none",
        "&::-webkit-scrollbar": {},
        "&::-webkit-scrollbar-thumb": {
          background: (theme) => theme.palette.secondary.main, // Color of the thumb (scrollbar handle)
          borderRadius: "6px",
        },
        "&::-webkit-scrollbar-thumb:hover": {
          background: "#FFC107",
        },
      }}
    >
      <Grid sx={{ minHeight: "100vh", height: "auto" }} item md={12}>
        {bussinessMangementMenu?.map((item, index) => {
          const indexRoute = bussinessMangementMenu.findIndex(
            (it) => it.systemFuctionName === item.systemFuctionName
          );
          const IconComponent = iconsObj[bussinessMangementMenu[indexRoute]?.icon];

          return (
            <Box
              key={"siderbar" + index}
              bgcolor="primary"
              sx={{
                padding: item.systemFuctionName === "Sign Out" ? "4px" : "10px",
                height:
                  item.systemFuctionName !== "Sign Out" ? "1.5rem" : "3rem",
                borderBottom: "1px solid white",
                background: currentPage === index ? "#127093" : "",
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={async (e) => {
                navigate(item?.route);
              }}
            >
              {item.systemFuctionName === "Sign Out" && (
                <Box
                  sx={{
                    mr: "10px",
                    "& > svg": {
                      fill: "white",
                    },
                    display: "flex",
                    justifyContent: "left",
                    alignItems: "center",
                  }}
                >
                  <Fab
                    // color="red"
                    sx={{
                      backgroundColor: "red !important",
                      width: "40px",
                      height: "40px",
                    }}
                    // sx={{ float: "right" , }}
                    onClick={() => {
                      // setOpen(true);
                    }}
                  >
                    {/* <LogoutIcon /> */}
                    <PowerSettingsNewIcon />
                  </Fab>
                </Box>
              )}
              {item.systemFuctionName !== "Sign Out" && (
                <Box
                  sx={{
                    mr: 1,
                    "& > svg": {
                      fill: "white",
                    },
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {/* {IconComponent && <IconComponent />} */}
                </Box>
              )}
              <Typography variant="standard" color="white" textAlign={"left"}>
                {" "}
                {/* {item.regionalFunctionName} */}
                {(function () {
                          const indexRoute = headerMenu.findIndex(
                            (it) =>
                              it.systemFuctionName === item.systemFuctionName
                          );
                          // if (NameData[item?.systemFuctionName]) {
                          //   return item?.systemFuctionName;
                          // }
                          return headerMenu[indexRoute]?.regionalFunctionName;
                        })()}
              </Typography>
            </Box>
          );
        })}
      </Grid>
      <LogOutPop open={open} setOpen={setOpen} />
    </Grid>
    // </Box>
  );
}
