

export const validation_componetlary=(obj,radioType)=>{

    
    if(obj?.employeeId==''){
        return {status:false,text:'choose Employee'}
    }

    if(obj?.customerName==''){
        return {status:false,text:'Enter Component name'}
    }
    if(obj?.percentage=='' && radioType=="percentage"){
        return {status:false,text:'Enter percentage'}
    }
    if(obj?.amount=='' && radioType=="amount"){
        return {status:false,text:'Enter amount'}
    }
    
    return {status:true}
    
}

export const validation=(obj,radioType)=>{

    
    if(obj?.employeeId==''){
        return {status:false,text:'choose Employee'}
    }

    if(obj?.incentiveName==''){
        return {status:false,text:'Enter incentiveName'}
    }
    if(obj?.percentage=='' && radioType=="percentage"){
        return {status:false,text:'Enter percentage'}
    }
    if(obj?.amount=='' && radioType=="amount"){
        return {status:false,text:'Enter amount'}
    }
    if(obj?.minimumValue==''){
        return {status:false,text:'Enter minimumValue'}
    }
    if(obj?.maximumValue==''){
        return {status:false,text:'Enter maximumValue'}
    }
    return {status:true}
    
}


export const validation_dedcution=(obj,radioType)=>{

    
    if(obj?.employeeId==''){
        return {status:false,text:'choose Employee'}
    }

    if(obj?.componentName==''){
        return {status:false,text:'Enter Component name'}
    }
    if(obj?.percentage=='' && radioType=="percentage"){
        return {status:false,text:'Enter percentage'}
    }
    if(obj?.amount=='' && radioType=="amount"){
        return {status:false,text:'Enter amount'}
    }
    
    return {status:true}
    
}