import { Card, CardActionArea, Grid, Typography } from "@mui/material"
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Rating } from "./rating";

export const ReviewCart=({ele})=>{
    return (
        <Card
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          boxShadow: "2px 3px 7px 0px rgb(0 0 0 / 10%)",
          borderRadius: "8px",
          backgroundColor: "#fff",
          margin:'10px'
        }}
       
      >
        <CardActionArea
          sx={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            bgcolor: "#fff",
            "&:hover": {
              backgroundColor: "transparent",
              cursor: "default",
            },
          }} 

        >
          <Grid container spacing={1} sx={{ padding: 2 }}>
          <Grid xs={12}>
          <Grid container spacing={1}  >
            <Grid xs={6}>
            <AccountCircleIcon 
          sx={{ width: '100px', height: '100px', cursor: 'pointer' }}
        />

            </Grid>
            <Grid   xs={6} style={{display:'flex',alignItems:'center'}}>
            <Typography variant="h4">{ele?.review}</Typography> 
            </Grid>

            </Grid>
          </Grid>
            <Grid xs={6}>
            <Rating ratting={ele?.rating} />
            <Typography>{ele?.profilename}</Typography>
        
            </Grid>
            <Grid xs={6}>
            <Typography>{ele?.createdDate?.split("T")[0]}</Typography>


            </Grid>
          </Grid>
          </CardActionArea>
          </Card>
    )
}