import { Tab, Tabs } from "@mui/material";
import React from "react";

export const TabContainer=({value,setValue,tabValue,width})=>{
    // const [value, setValue] = React.useState("outstanding");

  const tabfn = (event, newValue) => {
    // setPageNo(1);

    setValue(newValue);
  };
    return (<Tabs
        value={value}
        onChange={tabfn}
        textColor="primary"
        indicatorColor="primary"
        aria-label="primary tabs example"
      > 

        {
        tabValue.map((ele,index)=>{
            return <Tab
            value={ele.value}
            label={ele.label}
            onClick={() => {
              // paginationApiCall(date, pageNo, "null", "paid");
            }}
            sx={{
              fontWeight: value === ele.value? "700" : "",
              width: width?width:"auto",
            }}
          />
  
  
        })
        }
      </Tabs>)
}