import { Box, Button, Grid, TextField, Typography } from "@mui/material"
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { addReview, getReview } from "./reviewAndRatingApi";
import { ReviewCart } from "./reviewCard";
import { Rating } from "./rating";
import { swalAlertPopProps } from "../../utils/commonUtils";
import Swal from "sweetalert2";
export const ReviewAndRating = () => {
    const param = useParams()

    const userDetail = useSelector((state) => state.reducer.userDetail);

    const [ratting, setRating] = useState(0)
    const [dd, setDd] = useState('')
    const [reviewCollection, setReviewCollection] = useState([])
    useEffect(() => {
        console.log("userDetail=== ", userDetail?.billerId)
    }, [userDetail])
    useEffect(() => {

        getReview({ billerId: parseFloat(param.id) }, (d) => {
            let dd = d?.data?.data?.list
            console.log(
                "res dd", d?.data?.data?.list
            )
            setReviewCollection(dd)
        })
    }, [])
    return (
        <Box sx={{
            padding: '30px'
        }}>
            <Typography>
                Tell others what do you think
            </Typography>


            <Rating ratting={ratting} setRating={setRating} />
            <TextField
                label="Enter your text"
                placeholder="Type here..."
                multiline
                value={dd}
                onChange={(e) => {
                    setDd(e.target.value)
                }}
                rows={4}  // You can change this value to control the number of visible rows
                variant="outlined"  // You can use "filled" or "standard" as well
                fullWidth
            />

            <Box
                style={{ width: '100%', textAlign: 'center' }}>
                <Button
                    style={{
                        borderRadius: '10px',
                        color: 'white',
                        width: '200px',

                        background: '#e80089ed'
                    }} onClick={() => {
                        if(!userDetail?.name){
                            Swal.fire({
                                title:'',
                                text: 'Please Log in',
                                icon: "warning",
                        
                                ...swalAlertPopProps,
                              });
                        }
                        else if(ratting==0){
                            Swal.fire({
                                title:'',
                                text: 'Enter Rating',
                                icon: "warning",
                        
                                ...swalAlertPopProps,
                              });
                        }
                        else if(dd.trim()==""){
                            Swal.fire({
                                title:'',
                                text: 'Enter Review',
                                icon: "warning",
                        
                                ...swalAlertPopProps,
                              });
                        }
                          
                        else{
                            addReview({
                                billerId: parseFloat(param.id),
                                ratting: ratting,
                                review: dd,
                                profileName: userDetail?.name
    
    
                            }, () => {
                                console.log()
                                setRating(0)
                                setDd('')

                                getReview({ billerId: parseFloat(param.id) }, (d) => {
                                    let dd = d?.data?.data?.list
                                    console.log(
                                        "res dd", d?.data?.data?.list
                                    )
                                    setReviewCollection(dd)
                                })
                            })
                        }
                        console.log(
                            {
                                billerId: param.id,
                                ratting: ratting,
                                review: dd,
                                profileName: userDetail?.name


                            })
                      
                    }}>Post</Button>
            </Box>
            <Grid container>
                {
                    reviewCollection.map((ele, index) => {
                        return (
                            <Grid xs={12} sm={6} lg={4} sx={{ padding: '10px' }}>
                                <ReviewCart ele={ele} />
                            </Grid>
                        )
                    })
                }
            </Grid>

        </Box>

    )
}