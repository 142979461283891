import { Container, Fab, Grid, Tab, Tabs } from "@mui/material";
import { TabContainer } from "../../common/tab";
import React, { useEffect, useState } from "react";
import { TextFieldSearch } from "../../common/fieldText";
import { getA, getAllDeduction, getAllSalaryComponent, getData } from "./paymentApi";
import AddIcon from "@mui/icons-material/Add";

import { useSelector } from "react-redux";
import PaymentModal from "./paymentModal";
import PoprchModal from "./popSearch";
import CardPayment from "./cardPayment";
import PaymentIncentiveModal from "./paymetIncent";
import PaymentDeduction from "./paymentDeduction";

const PaymentStruct = () => {
  const [chooseId, setChooseId] = useState(null);
  const [formda, setFormda] = useState({
    customerName: "",

    amount: "",
    percentage: "",
    employeeId: "",
  });
  const [formdat, setFormdat] = useState({
    employeeId: "",
    incentiveName: "",
    minimumValue: "",
    maximumValue:"",
    percentage: "",
    amount:""
  });
    const [formdatTDence, setFormdatTDence] = useState({ 
      employeeId: "",
      componentName: "",
      amount: "",
      percentage: "" 
  });
  const [collectionData, setCollectionData] = useState([]);
  const [searchModal, setSearchModal] = useState(false);
  const userDetail = useSelector((state) => state.reducer.userDetail);
  const [componentData, setComponentData] = useState([]);
  const [cardValue, setCardValue] = useState(null);
 
  const [formData,setFormKData]=useState({
    SalaryComponents:{
      formKey:{
        customerName: "",

        amount: "",
        percentage: "",
        employeeId: ""
      },
    },
    incentrive:{
      formKey:{
        employeeId: "",
        incentiveName: "",
        minimumValue: "",
        maximumValue: 0,
        percentage: 0
      },
    }
  })

  
  useEffect(() => {
    if (cardValue) {
      getA(
        {
          pageSize: 10000,
          page: 1,

          filter: "name",
          search: cardValue.name,
        },
        (data) => {
          console.log("responseddd ", data);
        }
      );
    }
  }, [cardValue]);

  const [collectionSalaryComponent, setCollectionOfSalaryComponent] = useState(
    []
  );
  

  const cardKet={
    SalaryComponents:[
      {
        key:'componentName',
        label:'Component Name'
      },
     {
        key:'amount',
        label:'Amount'
      },
     {
        key:'percentage',
        label:'Percentage'
      },
    ],
    
    incentrive:[
      {
       key:"incentiveName" ,
       label:"Incentive Name"
      },
      {
        key:"minimumValue" ,
        label:"Minimum Value"
       }, 
       {
        key:"maximumValue" ,
        label:"Maximum Value"

       },
       

       {
        key:"percentage" ,
        label:"percentage"
        
       },
    ],
    Deductions:[
      {
        key:"componentName" ,
        label:"Component Name"
       },
       {
         key:"amount" ,
         label:"Amount"
        },
        
        {
         key:"percentage" ,
         label:"Percentage"
         
        },
    ]
  }
  const [containerData,setContainerData]=useState({
    SalaryComponents:[],
    incentrive:[],
    Deductions:[]
    
})
  useEffect(() => {
    console.log("containerData ", containerData);
  }, [containerData]);
  const [value, setValue] = useState("SalaryComponents");

  const tabfn = (event, newValue) => {
    // setPageNo(1);

    setValue(newValue);
  };

  useEffect(() => {
    getData((data) => {
      console.log("data data ", data?.data?.data?.list);
      // setComponentData(data?.data?.data?.list);
      

      setContainerData((prev)=>{
        
        return {...prev,incentrive:data?.data?.data?.list}})
    
    });
    getAllSalaryComponent((data) => {
      console.log("data?.data?.data?.list", data?.data?.data?.list);
      
      setContainerData((prev)=>{
        
        return {...prev,SalaryComponents:data?.data?.data?.list}})
    });
    getAllDeduction((data) => {
      console.log("data?.data?.data?.list getall", data?.data?.data?.list);
      
      setContainerData((prev)=>{
        
        return {...prev,Deductions:data?.data?.data?.list}})
    });
    
  }, []);

  useEffect(() => {
    console.log("containerData ", containerData);
  }, [containerData]);

  const [open, setOpen] = useState(false);
  const [openDeduction, setopenDeduction] = useState(false);
  const [opent, setOpent] = useState(false);
  const [search, setSearchvalue] = useState("");
  return (
    <>
      <Container sx={{ mt: 2 }}>
        <Grid container>
          <Grid xs={12}>
            <TextFieldSearch search={search?.name} setOpen={setSearchModal}  />

            <TabContainer
              value={value}
              width={"33.33%"}
              setValue={setValue}
              tabValue={[
                { value: "SalaryComponents", label: "Salary Components" },
                { value: "incentrive", label: "INCENTIVE" },

                { value: "Deductions", label: "Deductions" },
              ]}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              my: "10px",
              fontWeight: "500",
              display: "flex",
              justifyContent: "right",
              alignItems: "center",
            }}
          >
            <Fab
              onClick={() => {
                // setOpen(true);
                if(value=="SalaryComponents"){
                  setOpen(true);
                }
                if(value=="incentrive"){
                  setOpent(true);
                }
                if(value=="Deductions"){
                  setopenDeduction(true);
                }
                
              }}
              sx={{
                textTransform: "capitalize",
                width: {
                  xs: "100%",
                  sm: "auto",
                },
                // p: "10px",
                whiteSpace: "nowrap",
                zIndex: "0",
              }}
              size="small"
              color="primary"
              variant="extended"
              aria-label="add"
            >
              Add
              <AddIcon />
            </Fab>
          </Grid>
        </Grid>
        <PoprchModal
          cardValue={cardValue}
          setCardValue={(data) => {
            setSearchvalue(data);
            setCardValue(data);
          }}
          callback={() => {}}
          searchModal={searchModal}
          setSearchModal={setSearchModal}
        />
        <PaymentIncentiveModal  callback={() => {
            // getAllSalaryComponent((data) => {
            //   console.log("data?.data?.data?.list", data?.data?.data?.list);
            //   // setCollectionOfSalaryComponent(data?.data?.data?.list);
            // });
            getData((data) => {
              console.log("data data ", data?.data?.data?.list);
              // setComponentData(data?.data?.data?.list);
              
        
              setContainerData((prev)=>{
                
                return {...prev,incentrive:data?.data?.data?.list}})
            
            });
          }}
          formda={formdat}
          setFormda={setFormdat}
          open={opent}
          setOpen={setOpent} 
          />

          
           <PaymentDeduction  callback={() => {
            // getAllSalaryComponent((data) => {
            //   console.log("data?.data?.data?.list", data?.data?.data?.list);
            //   // setCollectionOfSalaryComponent(data?.data?.data?.list);
            // });
            // getData((data) => {
            //   console.log("data data ", data?.data?.data?.list);
            //   // setComponentData(data?.data?.data?.list);
              
        
            //   setContainerData((prev)=>{
                
            //     return {...prev,incentrive:data?.data?.data?.list}})
            
            // });
            getAllDeduction((data) => {
              console.log("data?.data?.data?.list getall", data?.data?.data?.list);
              
              setContainerData((prev)=>{
                
                return {...prev,Deductions:data?.data?.data?.list}})
            });
          }}
          formda={formdatTDence}
          setFormda={setFormdatTDence}
          open={openDeduction}
          setOpen={setopenDeduction} />
          
        <PaymentModal
          callback={() => {
            // getAllSalaryComponent((data) => {
            //   console.log("data?.data?.data?.list", data?.data?.data?.list);
            //   // setCollectionOfSalaryComponent(data?.data?.data?.list);
            // });
            
            getAllSalaryComponent((data) => {
              console.log("data?.data?.data?.list", data?.data?.data?.list);
              
              setContainerData((prev)=>{
                
                return {...prev,SalaryComponents:data?.data?.data?.list}})
            });
            
          }}
          formda={formda}
          setFormda={setFormda}
          open={open}
          setOpen={setOpen}
        />
        <Grid container spacing={2}>
       
        {containerData[value].map((ele, index) => {
          if(search!="" && search?.id!=ele?.employeeId){
            return null
          }
              return (
                <Grid item lg={4}>
                  <CardPayment
                  
                    item={ele}
                    setOpen={(data)=>{
                      
                      if(value=="SalaryComponents"){
                        setOpen(data);
                      }
                      if(value=="incentrive"){
                        setOpent(data);
                      }
                       if(value=="Deductions"){
                  setopenDeduction(true);
                }
                    }}
                    setModalData={(data) => {
                      console.log("dataupdate ", data);
                      // setFormda({...formda,id:data.id,});
                      
              setFormda({...formda,id:data.id,
                employeeId:data?.employeeId,
                componentName:data?.componentName,
                amount:data?.amount,
                percentage:data?.percentage
              });
                      setFormdat({...formdat,id:data.id,
                        employeeId:data?.employeeId,
                        incentiveName:data?.incentiveName,
                        amount:data?.amount,
                        percentage:data?.percentage,
                        minimumValue:data?.minimumValue,
                        maximumValue:data?.maximumValue

                      });
                      setFormdatTDence({
                        employeeId:data?.employeeId,
                        componentName:data?.componentName,
                        amount:data?.amount,
                        percentage:data?.percentage
                      })


                    }}
                    cardKet={cardKet[value]}
                  />
                </Grid>
              );
            })}
              
        </Grid>
        {/* <Grid container spacing={2} mt={1}>
          {value == "SalaryComponents" &&
            collectionSalaryComponent.map((ele, index) => {
              return (
                <Grid item lg={4}>
                  <CardPayment
                    item={ele}
                    setOpen={setOpen}
                    setModalData={(data) => {
                      console.log("dataupdate ", data);

                      setFormda({
                        ...formda,
                        id: data.id,
                        employeeId: data?.employeeId,
                        componentName: data?.componentName,
                        amount: data?.amount,
                        percentage: data?.percentage,
                      });
                    }}
                  />
                </Grid>
              );
            })}
        </Grid> */}
      </Container>
    </>
  );
};

export default PaymentStruct;
