
import { ADDDEDUCTIONCOMONEN, ADDSALARYCOMPONENT, ADDSALARYINCENTIVE, GETACTIVEEMPLOYEELIST, GETALLADVANCEFOR, GETALLCUSTOMERADVACE, GETALLDEDUCTIONCOMPONEN, GETALLSALARYCOMPONENT, GETALLSALARYINCENTIVE, GETCOUNTERSALESCUSTOMERS, UPDATESALARYCOMPONENT, UPDATESALARYINCENTIVE } from "../../utils/api"
import axios from "../../utils/axios"

export const getA=async(payload,callback)=>{
    await axios.post(GETALLCUSTOMERADVACE,payload).then((res)=>{
        callback(res)
    }).catch((err)=>{
        console.log(err)
    })
}


export const getAllIncentive=async(callback)=>{
    await axios.get(GETACTIVEEMPLOYEELIST).then((res)=>{
        callback(res)
    }).catch((err)=>{
        console.log(err)
    })
}






export const getData=async(callback)=>{
    await axios.get(GETALLSALARYINCENTIVE).then((res)=>{
        callback(res)
    }).catch((err)=>{
        console.log(err)
    })
}


export const getAllSalaryComponent=async(callback)=>{
    await axios.get(GETALLSALARYCOMPONENT).then((res)=>{
        callback(res)
    }).catch((err)=>{
        console.log(err)
    })
}
export const getAllDeduction=async(callback)=>{
    await axios.get(GETALLDEDUCTIONCOMPONEN).then((res)=>{
        callback(res)
    }).catch((err)=>{
        console.log(err)
    })
} 

export const addSalaryIncentive=async(payload,callback)=>{
    await axios.post(ADDSALARYINCENTIVE,payload).then((res)=>{
        callback(res)
    }).catch((err)=>{
        console.log(err)
    })
}
export const addSalaryDeduction=async(payload,callback)=>{
    await axios.post(ADDDEDUCTIONCOMONEN,payload).then((res)=>{
        callback(res)
    }).catch((err)=>{
        console.log(err)
    })
}

export const updateSalaryIncentive=async(payload,callback)=>{
    await axios.post(UPDATESALARYINCENTIVE,payload).then((res)=>{
        callback(res)
    }).catch((err)=>{
        console.log(err)
    })
}


export const updateSalaryDeduction=async(payload,callback)=>{
    await axios.post(UPDATESALARYINCENTIVE,payload).then((res)=>{
        callback(res)
    }).catch((err)=>{
        console.log(err)
    })
}





export const addSalaryComponent=async(payload,callback)=>{
    await axios.post(ADDSALARYCOMPONENT,payload).then((res)=>{
        callback(res)
    }).catch((err)=>{
        console.log(err)
    })
}


export const updateSalaryComponent=async(payload,callback)=>{
    await axios.post(UPDATESALARYCOMPONENT,payload).then((res)=>{
        callback(res)
    }).catch((err)=>{
        console.log(err)
    })
}
