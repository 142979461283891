import {
  Box,
  Breadcrumbs,
  Button,
  FormControlLabel,
  IconButton,
  Modal,
  Radio,
  RadioGroup,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Badge from "@mui/material/Badge";
import AddIcCallIcon from "@mui/icons-material/AddIcCall";
import Grid from "@mui/material/Grid";

import ReactToPrint from "react-to-print";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import SearchIcon from "@mui/icons-material/Search";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import CancelIcon from "@mui/icons-material/Cancel";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import fireDb from "../../utils/firebase";
import { useDispatch, useSelector } from "react-redux";
import { routePath } from "../../constants/routePath";
import DiscountModal from "../../common/discountModel";
import Swal from "sweetalert2";
import MenuSideBar from "../../common/menuSideBar";
import KotPrint from "../../common/kotPrint";
import PrintBilling from "../../common/printBilling";
import QuantityModal from "./quantityModal";
import {
  addBillCounterSell,
  customerNameByMobile,
} from "./counterSellBillingApi";
import {
  // addBill,
  displayKotList,
  qtySet,
  removeFromBookedNode,
  removeItem,
  removetableOrder,
  setKOT,
  tableOrderInFirebase,
} from "./fireBaseFunctions";
import { setBillNo, setTableName } from "../../app/store/reducer";
import {
  addBill,
  getcustomerNameByMobile,
  handleDataChange,
} from "./counterSellBillingCustomApiHandler";
import {
  checkCartIsEmpty,
  creditValidtionCheck,
} from "./counterSellBillingValidationFunctions";
import { swalAlertPopProps } from "../../utils/commonUtils";
import {
  ApplyAccordingREAndCBUser,
  ConditionalNameCbUserSetter,
  ConditionalNameReUserSetter,
  addProductInAddtoCartCBUserFn,
  addProductInFireBase,
  addQtyCBUser,
  calCulatePriceOfCBUsersProduct,
  calCulatePriceOfREUsersProduct,
  deleteCBUserProduct,
  discountedAmount,
  handleRenderingSnacBar,
  resetAddToCart,
  savePrintBill,
  setQtyCbUser,
  subQtyCBUser,
} from "./counterSellBillFunctions";
import { callbackHandlerCounterSellBilling_ } from "./counterSellBillingSetParameter";
import {
  getItemDetails,
  shareOnWhatsApp,
} from "../../common/commonUtils/commonUtilFunction";
import DisplayAnnualRecharge from "../smbAccoundReacharge/displayAnnualRecharge";
import { TableD } from "./data";
import {
  PDfSend,
  handleSendFile,
  sendToAPI,
} from "../../common/printBillingApi";
import { html2pdf } from "html2pdf.js";
import PrintBillingPdf from "../../common/printBillingPdf";
export default function CounterSellBilling() {
  const navigate = useNavigate();
  const componentRef = useRef();
  const componentRefKot = useRef();
  const componentRefPDF = useRef();

  const [tableData, setTableData] = useState(null);
  const [addtoCardProductCBUser, setAddtoCardProductCBUser] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [openRechargeModal, setOpenRechargeModal] = useState(false);
  const handleClose = () => setOpen(false);
  const [open2, setOpen2] = React.useState(false);
  const handleClose2 = () => setOpen2(false);
  const [open3, setOpen3] = React.useState(false);

  const [open4, setOpen4] = React.useState(false);
  const handleClose3 = () => setOpen3(false);
  const [addToCard, setAddToCard] = useState([]);
  const billNumber = useSelector((state) => state.reducer.billNumber);

  const handleCloseModal = () => setOpenRechargeModal(false);

  const setbillData = {
    amount: 0,
    discountType: "Amount",
    paymentMode: "Cash",
    discount: 0,
    paybill: 0,
    customer: "",
    customerMobile: "",
    requiredAmount:""
  };
  const [bill, setBill] = useState({
    amount: 0,
    discountType: "Amount",
    paymentMode: "Cash",
    discount: 0,
    paybill: 0,
    customer: "",
    customerMobile: "",
    sumOfGst: 0,
    priceWithoutGst: 0,

    requiredAmount:'',

  });
  const [billNoR,setBillNoRset]=useState(null)
  useEffect(()=>{
    if(!billNoR){
      return
    }
    handleSendFile(componentRefPDF, billNoR, (data) => {
      console.log(
        "File sent successfully ",
        data,
        " ",
        bill?.customerMobile,
        " ",
        data?.data?.pdfBillPath
      );

      const whatsappLink = `https://web.whatsapp.com/send?phone=${bill?.customerMobile}&text=${'Thanks for visiting PALACE THE SALON Your bill amount is Rs. '+bill.amount+' Your Receipt -'+data?.data?.pdfBillPath+' - Elegant Prime'}`;
      window.open(whatsappLink, "_blank");
      removetableOrder(fireDb, userDetail, tableName, navigate, routePath);

      setEventHandle({
        add: false,
        billNumber: null,
      });
      // shareOnWhatsApp(
      //           userDetail,
      //           getItemDetails,
      //           bill,
      //           eventHandle?.billNumber,
      //           bill.paybill,
      //           tableName,
      //           bill.  amount.toFixed(2),
      //           ApplyAccordingREAndCBUser,
      //           currentDataArr,
      //           addtoCardProductCBUser,
      //           discountedAmount(bill),
      //           data?.pdfBillPath // Use the PDF link generated by PDfSend
      //       );
    });
    console.log("billppp priceWithoutGst componentRef ", componentRef);
  },[componentRef,billNoR])
  useEffect(() => {
    console.log("billppp priceWithoutGst ", bill.priceWithoutGst);
  }, [bill]);
  const [modalQuantity, setModalQuantity] = useState({
    qty: 0,
    fcmRowId: "",
  });

  const [tableOpen, setTableOpen] = useState(false);
  const [kotItem, setKotItem] = useState([]);
  const [indexTableItem, setIndexTableItem] = useState();
  const [addToCardWindow, setAddToCardWindow] = useState(false);
  const [CollectionTableItems, setCollectionTableItems] = useState([]);

  const [saveClick, setSaveClick] = useState(false);
  let ss = "cb";
  useEffect(() => {
    const parentNodeRef = ApplyAccordingREAndCBUser(
      userDetail,
      () => {
        return fireDb.child(
          `${userDetail?.mobileNumber}/TableOrder/${tableName}`
        );
      },
      () => {
        return dispatch(setTableName(userDetail?.name));
      }
    );
    ApplyAccordingREAndCBUser(
      userDetail,
      () => {
        return parentNodeRef?.on("value", (snapshot) => {
          handleDataChange(snapshot, setBill, bill, setCurrentDataArr);
        });
      },
      () => {
        return dispatch(setTableName(userDetail?.name));
      }
    );
    // const parentNodeRef = fireDb.child(
    //   `${userDetail?.mobileNumber}/TableOrder/${tableName}`
    // );

    // parentNodeRef.on("value", (snapshot) => {
    //   handleDataChange(snapshot, setBill, bill, setCurrentDataArr);
    // });

    // Cleanup the listener when the component unmounts
    return () => {
      // parentNodeRef?.off("value", (snapshot) => {
      //   handleDataChange(snapshot, setBill, bill, setCurrentDataArr);
      // });
      ApplyAccordingREAndCBUser(
        userDetail,
        () => {
          return parentNodeRef?.off("value", (snapshot) => {
            handleDataChange(snapshot, setBill, bill, setCurrentDataArr);
          });
        },
        () => {
          return "";
        }
      );
    };
  }, []);
  // useEffect(()=>{

  // },[addToCardWindow])
  const [eventHandle, setEventHandle] = useState({
    add: false,
    billNumber: null,
    componentRef: null,
  });
  // useEffect(() => {
  //   console.log(
  //     "callBack function t",
  //     eventHandle.billNumber,
  //     " component  ",
  //     componentRef
  //   );

  //   if (eventHandle.billNumber && eventHandle.add) {
  //     console.log(
  //       "callBack function componentRef ",
  //       componentRef,
  //       " ",
  //       eventHandle
  //     );
  //     let coRef = componentRef,
  //       billNumber = eventHandle?.billNumber;
  //     handleSendFile(coRef, billNumber, (data) => {
  //       console.log(
  //         "File sent successfully ",
  //         data,
  //         " ",
  //         bill?.customerMobile,
  //         " "
  //       );

  //       const whatsappLink = `https://web.whatsapp.com/send?phone=${bill?.customerMobile}&text=${data?.data?.pdfBillPath}`;
  //       window.open(whatsappLink, "_blank");
  //       setEventHandle({
  //         add: false,
  //         billNumber: null,
  //       });
  //       // shareOnWhatsApp(
  //       //           userDetail,
  //       //           getItemDetails,
  //       //           bill,
  //       //           eventHandle?.billNumber,
  //       //           bill.paybill,
  //       //           tableName,
  //       //           bill.amount.toFixed(2),
  //       //           ApplyAccordingREAndCBUser,
  //       //           currentDataArr,
  //       //           addtoCardProductCBUser,
  //       //           discountedAmount(bill),
  //       //           data?.pdfBillPath // Use the PDF link generated by PDfSend
  //       //       );
  //     });
  //   }
  // }, [componentRef, eventHandle]);

  useEffect(() => {
    if (componentRef) {
      setEventHandle({ ...eventHandle, componentRef: componentRef });
    }
  }, [componentRef]);   
  const [currentDataArr, setCurrentDataArr] = useState(null);
  const [renderPage, setRenderPaage] = useState(false);

  const userDetail = useSelector((state) => state.reducer.userDetail);
  const tableName = useSelector((state) => state.reducer.tableName);
  useEffect(() => {
    console.log("currentDataArr ", currentDataArr);
    if (currentDataArr?.length != 0) {
      setAddToCardWindow(true);
    }
  }, [currentDataArr]);
  const dispatch = useDispatch();
  const payloadAddBill = {};
  const callBack = (data) => {
    console.log("callBack function", data, " component  ", eventHandle.componentRef);
    // handleSendFile(eventHandle.componentRef, data?.billNumber, (data) => {
    //   console.log(
    //     "File sent successfully ",
    //     data,
    //     " ",
    //     bill?.customerMobile,
    //     " "
    //   );

    //   const whatsappLink = `https://web.whatsapp.com/send?phone=${bill?.customerMobile}&text=${data?.data?.pdfBillPath}`;
    //   window.open(whatsappLink, "_blank");
    //   setEventHandle({
    //     add: false,
    //     billNumber: null,
    //   });
    //   // shareOnWhatsApp(
    //   //           userDetail,
    //   //           getItemDetails,nvm
    //   //           bill,
    //   //           eventHandle?.billNumber,
    //   //           bill.paybill,
    //   //           tableName,
    //   //           bill.  amount.toFixed(2),
    //   //           ApplyAccordingREAndCBUser,
    //   //           currentDataArr,
    //   //           addtoCardProductCBUser,
    //   //           discountedAmount(bill),
    //   //           data?.pdfBillPath // Use the PDF link generated by PDfSend
    //   //       );
    // });

    // setEventHandle({add:true,billNumber:data?.billNumber})
  };
  const callbackHandlerCounterSellBilling = callbackHandlerCounterSellBilling_(
    addBill,
    payloadAddBill,
    addBillCounterSell,
    Swal,
    dispatch,
    setBillNo,
    navigate
  );
  useEffect(() => {
    if (currentDataArr !== null && currentDataArr.length === 0) {
      removeFromBookedNode(fireDb, userDetail, tableName, navigate, routePath);
    }
    calCulatePriceOfREUsersProduct(
      currentDataArr,
      addtoCardProductCBUser,
      bill,
      setBill,
      setSaveClick,
      (data) => {
        setTableData(data);
      }
    );
  }, [currentDataArr]);
  useEffect(() => {
    calCulatePriceOfCBUsersProduct(
      addtoCardProductCBUser,
      bill,
      setBill,
      setSaveClick
    );
  }, [addtoCardProductCBUser]);

  const saveBill = async (callback,NotremoveCall) => {
    const payloadAddBill = {
      customer: bill.paymentMode === "Credit" ? bill.customer : tableName,
      customerMobile: bill.customerMobile,
      isCounterSalesBill: bill.paymentMode === "Credit" ? false : true,
      paymentMode: bill.paymentMode,
      discountType: bill.discountType,
      discount: parseFloat(bill.discount),
      settleBill: true,
      counterSalesChild: [],
    };
    currentDataArr.forEach((item, index) => {
      console.log("cal item ", item);
      payloadAddBill.counterSalesChild.push({
        itemId: item[1].id,
        rate: item[1].currentPrice,
        quantity: item[1].suppliedQuantity,
        amount: item[1].price * item[1].suppliedQuantity,
        itemDescription: item[1].itemDescription,

        unit: item[1].unit,
        gst: item[1].gst,
        gstAmount: item[1].gst,
      });
    });
    const calb = await customerNameByMobile({
      customerMobile: bill.customerMobile,
    });
    console.log("calb ", calb);
    // if (bill?.paymentMode === "Credit" && calb?.data?.data) {
    //   Swal.fire({
    //     text: "Bill credited to user's account",
    //     icon: "warning",
    //     ...swalAlertPopProps,
    //   });
    //   return null;
    // }

    setSaveClick(true);
    // const callbackHandlerCounterSellBilling =
    //   callbackHandlerCounterSellBilling_(
    //     addBill,
    //     payloadAddBill,
    //     addBillCounterSell,
    //     Swal,
    //     dispatch,
    //     setBillNo,
    //     navigate
    //   );
    if (callback) {
      callbackHandlerCounterSellBilling?.addBill?.callback({
        ...callbackHandlerCounterSellBilling?.addBill?.parameter,
        callBack: callback,
        payloadAddBill: payloadAddBill,
      });
    } else {
      callbackHandlerCounterSellBilling?.addBill?.callback({
        ...callbackHandlerCounterSellBilling?.addBill?.parameter,
        callBack: callBack,
        payloadAddBill: payloadAddBill,
      });
    }
    // addBill(
    //   data,
    //   addBillCounterSell,
    //   Swal,
    //   dispatch,
    //   setBillNo,
    //   navigate,
    //   routePath
    // );

      

    if(!NotremoveCall){
      removetableOrder(fireDb, userDetail, tableName, navigate, routePath);
    }
    
  };
  const saveBillCbUser = async () => {
    const payloadAddBill = {
      // customer: bill.paymentMode === "Credit" ? bill.customer : tableName,
      customer: ApplyAccordingREAndCBUser(
        userDetail,
        () => {
          return ConditionalNameReUserSetter(bill, tableName);
        },
        () => {
          return ConditionalNameCbUserSetter(bill);
        }
      ),
      customerMobile: bill.customerMobile,
      isCounterSalesBill: bill.paymentMode === "Credit" ? false : true,
      paymentMode: bill.paymentMode,
      discountType: bill.discountType,
      discount: parseFloat(bill.discount),
      settleBill: true,
      counterSalesChild: [],
    };
    addtoCardProductCBUser.forEach((item, index) => {
      console.log("cal item ", item);
      payloadAddBill.counterSalesChild.push({
        itemId: item.id,
        rate: item.currentPrice,
        quantity: item.suppliedQuantity,
        amount: item.price * item.suppliedQuantity,
        itemDescription: item.itemDescription,

        unit: item.unit,
        gst: item.gst,
        gstAmount: item.gst,
      });
    });
    const calb = await customerNameByMobile({
      customerMobile: bill.customerMobile,
    });
    console.log("calb ", calb);
    // if (bill?.paymentMode === "Credit" && calb?.data?.data) {
    //   Swal.fire({
    //     text: "Bill credited to user's account",
    //     icon: "warning",
    //     ...swalAlertPopProps,
    //   });
    //   return null;
    // }

    setSaveClick(true);
    // const callbackHandlerCounterSellBilling =
    //   callbackHandlerCounterSellBilling_(
    //     addBill,
    //     payloadAddBill,
    //     addBillCounterSell,
    //     Swal,
    //     dispatch,
    //     setBillNo,
    //     navigate
    //   );
    // callbackHandlerCounterSellBilling?.addBill?.callback(
    //   callbackHandlerCounterSellBilling?.addBill?.parameter
    // );
    callbackHandlerCounterSellBilling?.addBill?.callback({
      ...callbackHandlerCounterSellBilling?.addBill?.parameter,
      callBack: callBack,
      payloadAddBill: payloadAddBill,
    });
    //resetObjectAfterSubmit
    resetAddToCart(
      setAddtoCardProductCBUser,
      setBill,
      setbillData,
      setAddToCardWindow
    );
    // setAddtoCardProductCBUser([]);
    // setBill(setbillData);
    // setAddToCardWindow(false);
    // addBill(
    //   data,
    //   addBillCounterSell,
    //   Swal,
    //   dispatch,
    //   setBillNo,
    //   navigate,
    //   routePath
    // );
    // removetableOrder(fireDb, userDetail, tableName, navigate, routePath);
  };
  const handlePrint = () => {
    if (componentRef && componentRef.current) {
      componentRef.current.onClick();
      const opt = {
        margin: 1,
        filename: `bill-${44}.pdf`,
        html2canvas: { scale: 2 },
        jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
      };

      // Convert HTML content to PDF
      html2pdf()
        .from(componentRef.current)
        .set(opt)
        .save()
        .then((pdfBlob) => {
          // After generating the PDF, send it to the API
          sendToAPI(pdfBlob);
        });
    }
  };
  useEffect(() => {
    console.log("addtoCardProductCBUser ", addtoCardProductCBUser);
  }, [addtoCardProductCBUser]);
  useEffect(() => {
    ApplyAccordingREAndCBUser(
      userDetail,
      () => {
        return displayKotList(
          fireDb,
          userDetail,
          tableName,
          setKotItem,
          setBill,
          bill,
          // setCurrentDataArr,
          setOpen2,
          Swal
        );
      },
      () => {
        return (
          addtoCardProductCBUser.filter((item) => item?.printQty > 0)?.length >
          0
        );
        // ? setOpen2(true)
        // : Swal.fire({
        //     text: "Please Select New Product ",
        //     icon: "warning",
        //     ...swalAlertPopProps,
        //   });
      }
    );
  }, [
    ApplyAccordingREAndCBUser(
      userDetail,
      () => {
        return currentDataArr;
      },
      () => {
        return addtoCardProductCBUser;
      }
    ),
  ]);
  const handleAddToCart = (item) => {
    if (userDetail.accountValidityRemainingDays < 0) {
      setOpenRechargeModal(true);
    } else {
      setAddToCardWindow(true);
      setRenderPaage(true);
      return ApplyAccordingREAndCBUser(
        userDetail,
        () => {
          addProductInFireBase(
            currentDataArr,
            setAddToCardWindow,
            tableOrderInFirebase,
            setAddToCard,
            item,
            fireDb,
            userDetail,
            tableName,
            setBill,
            bill,
            setCurrentDataArr,
            qtySet,
            addToCard
          );
        },
        () => {
          addProductInAddtoCartCBUserFn(
            item,
            addtoCardProductCBUser,
            setAddtoCardProductCBUser
          );
        }
      );
    }
  };

  console.log(bill.paybill, "bill.paybill");
  return (
    <Box>
      <Grid container>
        <Grid
          xs={2}
          // sm={10}
          sm={2}
          sx={{
            backgroundColor: (theme) => theme.palette.primary.main,
            overflowY: "auto",
            height: "calc(100vh - 65px)",
            paddingRight: "4px",
            display: {
              xs: "none",
              sm: "block",
            },
          }}
        >
          {" "}
          <Box
            bgcolor="primary"
            sx={{
              padding: "20px",

              borderBottom: "1px solid white",
              display: "flex",
            }}
          >
            <Typography
              underline="none"
              color="white"
              sx={{
                fontWeight: 500,
                display: "flex",
                fontSize: "23px",
                height: "0.5rem",
                alignItems: "center",
              }}
            >
              Menu
            </Typography>
          </Box>
          <MenuSideBar
            CollectionTableItems={CollectionTableItems}
            setCollectionTableItems={setCollectionTableItems}
            indexTableItem={indexTableItem}
            setIndexTableItem={setIndexTableItem}
          />
        </Grid>
        <Grid item xs={12} sm={10} sx={{}}>
          <Grid container>
            <Grid
              item
              xs={7}
              lg={8}
              sx={{
                // p: "10px",
                p: "0px",

                overflowY: "auto",
                maxHeight: {
                  xs: "calc(100vh - 70px)",
                  // sm: "calc(100vh - 140px)",
                },
              }}
            >
              <Grid container gap={0}>
                <Grid
                  sx={{
                    backgroundColor: (theme) => theme.palette.primary.main,
                    p: "10px",
                    display: "flex",
                    m: 0,
                    height: "3rem",
                    alignItems: "center",
                  }}
                  item
                  xs={8}
                >
                  <Breadcrumbs
                    aria-label="breadcrumb"
                    sx={{
                      ".MuiBreadcrumbs-separator": {
                        color: "white",
                      },
                    }}
                  >
                    <Typography
                      underline="none"
                      color="white"
                      fontWeight={"700"}
                      sx={{
                        cursor: "pointer",
                      }}
                      onClick={() => navigate(routePath.COUNTERSELL)}
                    >
                      Counter Sale Billing
                    </Typography>

                    <Typography
                      underline="none"
                      color="white"
                      fontWeight={"700"}
                      sx={{
                        cursor: "pointer",
                      }}
                    >
                      {tableName}
                    </Typography>
                  </Breadcrumbs>
                </Grid>
                <Grid
                  sx={{
                    backgroundColor: (theme) => theme.palette.primary.main,
                    // p: "10px 0px",
                    display: "flex",
                    justifyContent: "space-around",
                    alignItems: "center",
                    height: "3rem",
                  }}
                  item
                  xs={4}
                >
                  <TextField
                    sx={{
                      background: "white",
                      float: "left",
                      borderRadius: "5px",
                      width: "80%",
                      p: "0px ",
                      "& .MuiOutlinedInput-input": {
                        height: "0px",
                        // paddingLeft:"10px"
                      },
                    }}
                    variant="outlined"
                    placeholder="Search in Menu"
                    InputProps={{
                      endAdornment: (
                        <IconButton type="submit" aria-label="search">
                          <SearchIcon />
                        </IconButton>
                      ),
                    }}
                  />
                  <Badge
                    badgeContent={
                      ApplyAccordingREAndCBUser(
                        userDetail,
                        () => {
                          return currentDataArr;
                        },
                        () => {
                          return addtoCardProductCBUser;
                        }
                      )?.length
                    }
                    color="secondary"
                  >
                    <ShoppingCartIcon
                      sx={{
                        float: "right",
                        fontSize: "30px",
                        color: "white",
                        cursor: "pointer",
                        // pt: 1
                      }}
                      onClick={() => {
                        handleRenderingSnacBar(
                          renderPage,
                          setRenderPaage,
                          setAddToCardWindow,
                          addToCardWindow
                        );
                        // setRenderPaage(true);
                        // if (renderPage === false) {
                        //   setAddToCardWindow(true);
                        // } else {
                        //   setAddToCardWindow(!addToCardWindow);
                        // }

                        // console.log(
                        //   "snapmsg ",
                        //   addToCardWindow === true,
                        //   " ",
                        //   saveClick === false,
                        //   " ",
                        //   renderPage === true,
                        //   " ",
                        //   ApplyAccordingREAndCBUser(
                        //     userDetail,
                        //     () => {
                        //       return currentDataArr;
                        //     },
                        //     () => {
                        //       return addtoCardProductCBUser;
                        //     }
                        //   )
                        // );

                        // setRenderPaage(false);
                      }}
                    />
                    <Snackbar
                      anchorOrigin={{ vertical: "top", horizontal: "center" }}
                      open={
                        addToCardWindow === true &&
                        saveClick === false &&
                        renderPage === true &&
                        ApplyAccordingREAndCBUser(
                          userDetail,
                          () => {
                            return currentDataArr;
                          },
                          () => {
                            return addtoCardProductCBUser;
                          }
                        ) &&
                        ApplyAccordingREAndCBUser(
                          userDetail,
                          () => {
                            return currentDataArr;
                          },
                          () => {
                            return addtoCardProductCBUser;
                          }
                        ).length === 0
                      }
                      onClose={() => setAddToCardWindow(false)}
                      message="No Item Added"
                    />
                  </Badge>
                </Grid>
                {console.log(
                  "show= ar ",
                  ApplyAccordingREAndCBUser(
                    userDetail,
                    () => {
                      return CollectionTableItems[indexTableItem]?.tableItems;
                    },
                    () => {
                      return addtoCardProductCBUser;
                    }
                  )
                )}
                {CollectionTableItems[indexTableItem]?.tableItems?.map(
                  (item, index) => {
                    // console.log("item name ", item);
                    return (
                      <Grid
                        item
                        key={"tableitems" + index}
                        xs={
                          ApplyAccordingREAndCBUser(
                            userDetail,
                            () => {
                              return currentDataArr;
                            },
                            () => {
                              return addtoCardProductCBUser;
                            }
                          )?.length > 0 && addToCardWindow
                            ? 12
                            : 12
                        }
                        sm={
                          ApplyAccordingREAndCBUser(
                            userDetail,
                            () => {
                              return currentDataArr;
                            },
                            () => {
                              return addtoCardProductCBUser;
                            }
                          ) > 0 && addToCardWindow
                            ? 6
                            : 4
                        }
                        md={
                          ApplyAccordingREAndCBUser(
                            userDetail,
                            () => {
                              return currentDataArr;
                            },
                            () => {
                              return addtoCardProductCBUser;
                            }
                          ) > 0 && addToCardWindow
                            ? 4
                            : 4
                        }
                        sx={{ p: "10px", textAlign: "center" }}
                      >
                        <Box
                          onClick={() => {
                            handleAddToCart(item);
                          }}
                          sx={{
                            p: "0px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontWeight: "bold",
                            height: "4rem",
                            borderRadius: "10px",
                            color: "#5693a3",

                            cursor: "pointer",
                            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                            ":hover": {
                              bgcolor: "#2892ba17",
                            },
                          }}
                        >
                          {item.itemDescription}
                        </Box>
                      </Grid>
                    );
                  }
                )}
              </Grid>
            </Grid>
            {
              // (currentDataArr?.length > 0 ||
              //   addtoCardProductCBUser?.length > 0) &&
              // ApplyAccordingREAndCBUser(
              //   userDetail,
              //   () => {
              //     return currentDataArr;
              //   },
              //   () => {
              //     return addtoCardProductCBUser;
              //   }
              // )?.length > 0 &&
              // addToCardWindow &&
              <Grid
                item
                xs={5}
                lg={4}
                sx={{
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                  p: "10px",

                  overflowY: "auto",
                  height: {
                    xs: "calc(100vh - 70px)",
                    // sm: "calc(100vh - 140px)",
                  },
                }}
              >
                <Grid container>
                  <Grid item xs={12}>
                    <Button
                      onClick={() => {
                        setTableOpen(true);
                      }}
                      variant="contained"
                      sx={{ fontSize: "x-small", float: "right" }}
                    >
                      {" "}
                      DetailFlow
                    </Button>
                    <ReactToPrint
                      trigger={() => (
                        <Button
                          variant="contained"
                          sx={{ fontSize: "x-small", float: "right" }}
                          onClick={() => {
                            // setKOT();
                            // setOpen2(false);
                          }}
                        >
                          {" "}
                          kot
                        </Button>
                      )}
                      content={() => componentRefKot.current}
                      onBeforePrint={async (e) => {
                        if (
                          ApplyAccordingREAndCBUser(
                            userDetail,
                            () => {
                              return kotItem;
                            },
                            () => {
                              return addtoCardProductCBUser.filter(
                                (item) => item?.printQty > 0
                              );
                            }
                          ).length == 0
                        ) {
                          Swal.fire({
                            text: "Please Select New Product ",
                            icon: "warning",

                            ...swalAlertPopProps,
                          });
                          e.stopPropagation();
                        }
                        ApplyAccordingREAndCBUser(
                          userDetail,
                          () => {
                            return setKOT(
                              fireDb,
                              userDetail,
                              tableName,
                              setBill,
                              bill,
                              setCurrentDataArr
                            );
                          },
                          () => {
                            return (function () {
                              let arr = [...addtoCardProductCBUser];

                              arr = arr.map((item) => {
                                return { ...item, printQty: 0 };
                              });

                              console.log("Add ss", arr);
                              setAddtoCardProductCBUser(arr);
                            })();
                          }
                        );
                        // setOpen2(false);
                        // After the async function completes, trigger printing
                        // componentRefKot.current.print();
                        // await ApplyAccordingREAndCBUser(
                        //   userDetail,
                        //   async () => {
                        //     // Execute your function and await its completion
                        //     await displayKotList(
                        //       fireDb,
                        //       userDetail,
                        //       tableName,
                        //       setKotItem,
                        //       setBill,
                        //       bill,
                        //       setCurrentDataArr,
                        //       setOpen2,
                        //       Swal
                        //     );
                        //     // Once the function is done and content is ready, trigger printing
                        //     // componentRefKot.current.print();
                        //   },
                        //   () => {
                        //     return addtoCardProductCBUser.filter(
                        //       (item) => item?.printQty > 0
                        //     )?.length > 0
                        //       ? setOpen2(true)
                        //       : Swal.fire({
                        //           text: "Please Select New Product ",
                        //           icon: "warning",
                        //           ...swalAlertPopProps,
                        //         });
                        //   }
                        // );
                      }}
                    ></ReactToPrint>

                    <Grid container>
                      <Grid item xs={6} sx={{ display: "flex", p: "10px" }}>
                        <TextField
                          fullWidth
                          id="mobileNumber"
                          label="Mobile No"
                          value={bill.customerMobile}
                          onChange={(e) => {
                            let val = e.target.value.replace(/\D/g, "");
                            if (val.length <= 10)
                              setBill((prev) => {
                                let prevData = { ...prev };
                                prevData.customerMobile = val;
                                return prevData;
                              });
                            // setBill({
                            //   ...bill,
                            //   customerMobile: val,
                            // });
                          }}
                          sx={{
                            textAlign: "center",
                            "& .MuiInputLabel-root": {
                              lineHeight: "0.9rem",
                            },
                            "& .MuiInputBase-root": { height: "2.5rem" },
                            ...(bill.customer
                              ? {}
                              : {
                                  "& .MuiInputBase-root::after": {
                                    content: "unset",
                                  },
                                }),
                          }}
                          InputProps={{
                            endAdornment: (
                              <AddIcCallIcon
                                sx={{
                                  color: (theme) => theme.palette.primary.main,
                                }}
                              />
                            ),
                          }}
                        />
                      </Grid>

                      <Grid item xs={6} sx={{ display: "flex", p: "10px" }}>
                        <TextField
                          fullWidth
                          id="username"
                          label="Customer Name"
                          value={bill.customer}
                          onChange={(e) => {
                            setBill((prev) => {
                              let prevData = { ...prev };
                              prevData.customer = e.target.value;
                              return prevData;
                            });
                            // setBill({
                            //   ...bill,
                            //   customer: e.target.value,
                            // });
                          }}
                          // sx={{
                          //   textAlign: "center",
                          //   "& .MuiInputLabel-root": { lineHeight: "0.9rem" },
                          //   "& .MuiInputBase-root": { height: "2.5rem" },
                          // }}
                          sx={{
                            textAlign: "center",
                            "& .MuiInputLabel-root": {
                              lineHeight: "0.9rem",
                            },
                            "& .MuiInputBase-root": { height: "2.5rem" },
                            ...(bill.customer
                              ? {}
                              : {
                                  "& .MuiInputBase-root::after": {
                                    content: "unset",
                                  },
                                }),
                          }}
                          InputProps={{
                            style: {
                              paddingRight: "20px", // Set the desired height here
                            },
                            endAdornment: (
                              <AccountCircleIcon
                                sx={{
                                  background: "white",
                                  color: (theme) => theme.palette.primary.main,
                                }}
                              />
                            ),
                          }}
                        />
                      </Grid>

                      <Box
                        sx={{
                          display: "flex",
                          width: "100%",
                          flexDirection: "column",
                          marginTop: "10px",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            borderBottom: "1px solid",

                            borderColor: "divider",
                            backgroundColor: "#f5f5f5",
                            padding: "8px 16px",
                            fontWeight: "600",
                          }}
                        >
                          <Box sx={{ flex: 1.5 }}></Box>
                          <Box sx={{ flex: 5.0 }}> Item</Box>

                          <Box sx={{ flex: 3.5, textAlign: "left" }}>Qty</Box>
                          <Box sx={{ flex: 1 }}> Price</Box>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          overflowY: "auto",
                          height: "calc(40vh - 65px)",
                          width: "100%",
                        }}
                      >
                        {ApplyAccordingREAndCBUser(
                          userDetail,
                          () => {
                            console.log("currentData", currentDataArr);
                            return currentDataArr;
                          },
                          () => {
                            console.log(
                              "currentData 2",
                              addtoCardProductCBUser
                            );
                            return addtoCardProductCBUser;
                          }
                        )?.map((item, index) => (
                          <Grid
                            key={index}
                            container
                            alignItems="center"
                            style={{
                              borderBottom: "1px solid",
                              borderColor: "divider",
                              padding: "8px 16px",
                              backgroundColor: index % 2 === 1 ? "#f5f5f5" : "",
                            }}
                          >
                            <Grid item xs={1.5}>
                              <CancelIcon
                                sx={{ color: "#ff2e2e", cursor: "pointer" }}
                                onClick={() => {
                                  console.log("delete gnn");
                                  if (
                                    ApplyAccordingREAndCBUser(
                                      userDetail,
                                      () => {
                                        return currentDataArr;
                                      },
                                      () => {
                                        return addtoCardProductCBUser;
                                      }
                                    )?.length == 1
                                  ) {
                                    setAddToCardWindow(false);
                                  }
                                  ApplyAccordingREAndCBUser(
                                    userDetail,
                                    () => {
                                      return removeItem(
                                        "+",
                                        item[1],
                                        item[1]?.fcmRowId,
                                        fireDb,
                                        userDetail,
                                        tableName,
                                        setBill,
                                        setCurrentDataArr,
                                        bill
                                      );
                                    },
                                    () => {
                                      return deleteCBUserProduct(
                                        addtoCardProductCBUser,
                                        setAddtoCardProductCBUser,
                                        index
                                      );
                                    }
                                  );
                                }}
                              />
                            </Grid>
                            <Grid
                              item
                              xs={4}
                              fontWeight="400"
                              color={(theme) => theme.palette.primary.main}
                            >
                              {/* {item[1]?.itemDescription} */}
                              {ApplyAccordingREAndCBUser(
                                userDetail,
                                () => {
                                  return item[1]?.itemDescription;
                                },
                                () => {
                                  return item?.itemDescription;
                                }
                              )}
                            </Grid>

                            <Grid
                              item
                              // sx={{}}
                              xs={3.5}
                              display="flex"
                              alignItems="center"
                            >
                              <Box
                                sx={{
                                  position: "relative",
                                  height: "100%",
                                  width: "100%",
                                }}
                              >
                                <Box
                                  sx={{
                                    position: "absolute",
                                    display: "flex",
                                    alignItems: "center",
                                    top: "0px",
                                    height: "100%",
                                    width: "100%",
                                  }}
                                >
                                  {" "}
                                  <RemoveIcon
                                    style={{
                                      color: "#333",
                                      border: "1px solid #333",
                                      margin: "5px",
                                      fontSize: "12px",
                                      borderRadius: "4px",
                                      padding: "4px 8px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      ApplyAccordingREAndCBUser(
                                        userDetail,
                                        () => {
                                          return (function () {
                                            if (
                                              item[1]?.suppliedQuantity === 1
                                            ) {
                                              removeItem(
                                                "+",
                                                item[1],
                                                item[1]?.fcmRowId,
                                                fireDb,
                                                userDetail,
                                                tableName,
                                                setBill,
                                                setCurrentDataArr,
                                                bill
                                              );
                                            }
                                            qtySet(
                                              "-",
                                              null,
                                              item[1]?.fcmRowId,
                                              fireDb,
                                              userDetail,
                                              tableName,
                                              setCurrentDataArr
                                            );
                                          })();
                                        },
                                        () => {
                                          return subQtyCBUser(
                                            index,
                                            addtoCardProductCBUser,
                                            setAddtoCardProductCBUser
                                          );
                                        }
                                      );
                                      // if (item[1]?.suppliedQuantity === 1) {
                                      //   removeItem(
                                      //     "+",
                                      //     item[1],
                                      //     item[1]?.fcmRowId,
                                      //     fireDb,
                                      //     userDetail,
                                      //     tableName,
                                      //     setBill,
                                      //     setCurrentDataArr,
                                      //     bill
                                      //   );
                                      // }
                                      // qtySet(
                                      //   "-",
                                      //   null,
                                      //   item[1]?.fcmRowId,
                                      //   fireDb,
                                      //   userDetail,
                                      //   tableName,
                                      //   setCurrentDataArr
                                      // );
                                    }}
                                  ></RemoveIcon>
                                  {ApplyAccordingREAndCBUser(
                                    userDetail,
                                    () => {
                                      return item[1]?.suppliedQuantity;
                                    },
                                    () => {
                                      return item?.suppliedQuantity;
                                    }
                                  )}
                                  {}
                                  <ArrowDropDownIcon
                                    sx={{
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      setOpen4(true);
                                      ApplyAccordingREAndCBUser(
                                        userDetail,
                                        () => {
                                          return setModalQuantity({
                                            ...modalQuantity,
                                            qty: item[1]?.suppliedQuantity,
                                            fcmRowId: item[1]?.fcmRowId,
                                          });
                                        },
                                        () => {
                                          return setModalQuantity({
                                            ...modalQuantity,
                                            qty: item?.suppliedQuantity,
                                            elementIndex: index,
                                          });
                                        }
                                      );
                                    }}
                                  />
                                  <AddIcon
                                    style={{
                                      color: "#333",
                                      border: "1px solid #333",
                                      margin: "5px",
                                      fontSize: "12px",
                                      borderRadius: "4px",
                                      padding: "4px 8px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      ApplyAccordingREAndCBUser(
                                        userDetail,
                                        () => {
                                          return qtySet(
                                            "+",
                                            null,
                                            item[1]?.fcmRowId,
                                            fireDb,
                                            userDetail,
                                            tableName,
                                            setCurrentDataArr
                                          );
                                        },
                                        () => {
                                          return addQtyCBUser(
                                            index,
                                            addtoCardProductCBUser,
                                            setAddtoCardProductCBUser
                                          );
                                        }
                                      );
                                      // qtySet(
                                      //   "+",
                                      //   null,
                                      //   item[1]?.fcmRowId,
                                      //   fireDb,
                                      //   userDetail,
                                      //   tableName,
                                      //   setCurrentDataArr
                                      // );
                                    }}
                                  ></AddIcon>
                                </Box>
                              </Box>
                            </Grid>
                            <Grid
                              item
                              xs={3}
                              fontWeight="400"
                              display="flex"
                              alignItems="center"
                              sx={{
                                justifyContent: "right",
                              }}
                              color={(theme) => theme.palette.secondary.main}
                              // textAlign="right"
                            >
                              {ApplyAccordingREAndCBUser(
                                userDetail,
                                () => {
                                  return item[1]?.currentPrice.toFixed(2);
                                },
                                () => {
                                  return item?.currentPrice.toFixed(2);
                                }
                              )}
                              {/* {item[1].price.toFixed(2)} */}
                            </Grid>
                          </Grid>
                        ))}
                      </Box>
                      <Box sx={{ p: "20px" }}>
                        <Grid container sx={{ m: 0, p: 0 }}>
                          <Grid item xs={8}>
                            <Box
                              textAlign="left"
                              sx={{
                                fontWeight: "500",
                                color: (theme) => theme.palette.primary.main,
                              }}
                            >
                              Bill Amount
                            </Box>
                          </Grid>
                          <Grid item xs={4}>
                            <Box
                              textAlign="right"
                              sx={{
                                fontWeight: "500",
                                display: "flex",
                                justifyContent: "right",
                                alignItems: "center",
                                color: (theme) => theme.palette.secondary.main,
                              }}
                            >
                              <CurrencyRupeeIcon sx={{ height: "17px" }} />{" "}
                              {bill?.priceWithoutGst?.toFixed(2)}
                            </Box>
                          </Grid>
                          <Grid
                            item
                            xs={6}
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Box
                              textAlign="left"
                              sx={{
                                cursor: "pointer",
                                textDecoration: "underline",
                                fontWeight: "500",
                                color: (theme) => theme.palette.primary.main,
                              }}
                              onClick={() => setOpen(true)}
                            >
                              Discount
                            </Box>
                            <Box
                              sx={{
                                fontWeight: "500",
                                display: "flex",
                                justifyContent: "right",
                                alignItems: "center",
                                color: (theme) => theme.palette.secondary.main,
                              }}
                            >
                              {" "}
                              {bill.discountType === "Percent" && (
                                <>{bill.discount + "%"}</>
                              )}
                            </Box>
                          </Grid>
                          <Grid item xs={3}></Grid>
                          <Grid item xs={3}>
                            <Box
                              textAlign="right"
                              sx={{
                                fontWeight: "500",
                                display: "flex",
                                justifyContent: "right",
                                alignItems: "center",
                                color: (theme) => theme.palette.secondary.main,
                              }}
                            >
                              <CurrencyRupeeIcon sx={{ height: "17px" }} />{" "}
                              {discountedAmount(bill)?.toFixed(2)}
                            </Box>
                          </Grid>
                          <Grid item xs={8}>
                            <Box
                              textAlign="left"
                              sx={{
                                fontWeight: "500",
                                color: (theme) => theme.palette.primary.main,
                              }}
                            >
                              GST(%)
                            </Box>
                          </Grid>
                          <Grid item xs={4}>
                            <Box
                              textAlign="right"
                              sx={{
                                fontWeight: "500",
                                display: "flex",
                                justifyContent: "right",
                                alignItems: "center",
                                color: (theme) => theme.palette.secondary.main,
                              }}
                            >
                              <CurrencyRupeeIcon sx={{ height: "17px" }} />{" "}
                              {bill.paybill?.toFixed(2) -
                                bill.priceWithoutGst?.toFixed(2)}
                            </Box>
                          </Grid>
                          <Grid item xs={8}>
                            <Box
                              textAlign="left"
                              sx={{
                                fontWeight: "500",
                                color: (theme) => theme.palette.primary.main,
                              }}
                            >
                              Payable Amount
                            </Box>
                          </Grid>
                          <Grid item xs={4}>
                            <Box
                              textAlign="right"
                              sx={{
                                fontWeight: "500",
                                display: "flex",
                                justifyContent: "right",
                                alignItems: "center",
                                color: (theme) => theme.palette.secondary.main,
                              }}
                            >
                              <CurrencyRupeeIcon sx={{ height: "17px" }} />{" "}
                              {bill.paybill.toFixed(2)}
                            </Box>
                          </Grid>
                          <Grid item xs={12}>
                            {/* <Box
                              textAlign="center"
                              sx={{
                                fontWeight: "500",
                                color: (theme) => theme.palette.primary.main,
                              }}
                            >
                              Choose Payment Mode
                            </Box> */}
                            <RadioGroup
                              row
                              sx={{
                                fontWeight: "500",
                                color: (theme) => theme.palette.secondary.main,
                                display: "flex",
                                justifyContent: "space-around",
                              }}
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              name="row-radio-buttons-group"
                              value={bill.paymentMode}
                              onChange={(e) => {
                                setBill((prev) => {
                                  let prevData = { ...prev };
                                  prevData.paymentMode = e.target.value;
                                  return prevData;
                                });
                                // setBill({
                                //   ...bill,
                                //   paymentMode: e.target.value,
                                // });
                              }}
                            >
                              <FormControlLabel
                                value="Cash"
                                control={<Radio />}
                                label={
                                  <Box sx={{ display: "flex" }}>
                                    <CurrencyRupeeIcon
                                      sx={{ fontSize: "20px" }}
                                    />
                                    Cash{" "}
                                  </Box>
                                }
                              />
                              <FormControlLabel
                                value="Credit"
                                selected={true}
                                control={<Radio />}
                                label={
                                  <Box sx={{ display: "flex" }}>
                                    <CurrencyRupeeIcon
                                      sx={{ fontSize: "20px" }}
                                    />
                                    Credit{" "}
                                  </Box>
                                }
                              />
                              <FormControlLabel
                                value="Digital"
                                control={<Radio />}
                                label={
                                  <Box sx={{ display: "flex" }}>
                                    <CurrencyRupeeIcon
                                      sx={{ fontSize: "20px" }}
                                    />
                                    Online{" "}
                                  </Box>
                                }
                              />
                            </RadioGroup>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sx={{
                              display: "flex",
                              justifyContent: "space-around",
                            }}
                          >
                            <Button
                              variant="contained"
                              sx={{ fontSize: "x-small" }}
                              onClick={async () => {
                                let isCartEmpty = checkCartIsEmpty(
                                  ApplyAccordingREAndCBUser(
                                    userDetail,
                                    () => {
                                      return currentDataArr;
                                    },
                                    () => {
                                      return addtoCardProductCBUser;
                                    }
                                  )
                                );
                                if (!isCartEmpty.status) {
                                  Swal.fire({
                                    text: isCartEmpty.msg,
                                    icon: "warning",
                                    ...swalAlertPopProps,
                                  });
                                  return;
                                }
                                let valid = creditValidtionCheck(bill);
                                if (valid.status === false) {
                                  Swal.fire({
                                    text: valid.msg,
                                    icon: "warning",
                                    ...swalAlertPopProps,
                                  });
                                } else {
                                  ApplyAccordingREAndCBUser(
                                    userDetail,
                                    () => {
                                      return saveBill();
                                    },
                                    () => {
                                      return saveBillCbUser();
                                    }
                                  );
                                }
                              }}
                            >
                              Save
                            </Button>
                            {ApplyAccordingREAndCBUser(
                              userDetail,
                              () => {
                                return currentDataArr;
                              },
                              () => {
                                return addtoCardProductCBUser;
                              }
                            )?.length == 0 && (
                              <Button
                                variant="contained"
                                sx={{ fontSize: "x-small" }}
                                onClick={async () => {
                                  let isCartEmpty = checkCartIsEmpty(
                                    ApplyAccordingREAndCBUser(
                                      userDetail,
                                      () => {
                                        return currentDataArr;
                                      },
                                      () => {
                                        return addtoCardProductCBUser;
                                      }
                                    )
                                  );
                                  if (!isCartEmpty.status) {
                                    // e.preventDefault();
                                    Swal.fire({
                                      text: isCartEmpty.msg,
                                      icon: "warning",
                                      ...swalAlertPopProps,
                                    });
                                    return;
                                  }
                                }}
                              >
                                Save & Print
                              </Button>
                            )}

                            {/* <Button
                                  variant="contained"
                                  sx={{ fontSize: "x-small" }}
                                  onClick={() => {
                                    handlePrint();
                                    // let valid = creditValidtionCheck(bill);
                                    // if (valid.status === false) {
                                    //   Swal.fire({
                                    //     text: valid.msg,
                                    //     icon: "warning",

                                    //     ...swalAlertPopProps,
                                    //   });
                                    // } else {
                                    //   setOpen3(true);
                                    // }
                                  }}
                                >
                                  Save & Print
                                </Button> */}
                            {ApplyAccordingREAndCBUser(
                              userDetail,
                              () => {
                                return currentDataArr;
                              },
                              () => {
                                return addtoCardProductCBUser;
                              }
                            )?.length !== 0 && (
                              <ReactToPrint
                                trigger={() => (
                                  <Button
                                    variant="contained"
                                    sx={{ fontSize: "x-small" }}
                                    onClick={() => {
                                      // setKOT();
                                      // addDataCallBack();
                                      // setOpen2(false);
                                    }}
                                  >
                                    {" "}
                                    Save & Print
                                  </Button>
                                )}
                                content={() => componentRef.current}
                                onBeforePrint={(e) => {
                                  const opt = {
                                    margin: 1,
                                    filename: `bill-${44}.pdf`,
                                    html2canvas: { scale: 2 },
                                    jsPDF: {
                                      unit: "in",
                                      format: "letter",
                                      orientation: "portrait",
                                    },
                                  };

                                  // Convert HTML content to PDF

                                  savePrintBill(
                                    bill,
                                    creditValidtionCheck,
                                    Swal,
                                    userDetail,
                                    saveBill,
                                    saveBillCbUser
                                  );
                                }}
                              ></ReactToPrint>
                            )}

                            <Button
                              variant="contained"
                              sx={{ fontSize: "x-small" }}
                              onClick={() => {
                                // addDataCallBack();
                                if (bill?.customerMobile?.length === 10) {
                                  // if (
                                  //   !
                                  // ) {
                                  //   return;
                                  // }
                                  saveBill(function(data){
                                    console.log("responce datao",data?.billNumber," ",componentRef)
                                    setBillNoRset(data?.billNumber)
                                  },true)
                                  // savePrintBill(
                                  //   bill,
                                  //   creditValidtionCheck,
                                  //   Swal,
                                  //   userDetail,
                                  //   saveBill,
                                  //   saveBillCbUser
                                  // );

                                  // addDataCallBack();
                                } else {
                                  Swal.fire({
                                    text: "Please Enter Mobile Number",
                                    icon: "error",

                                    ...swalAlertPopProps,
                                  });
                                }
                                // let valid = creditValidtionCheck(bill);
                                // if (valid.status === false) {
                                //   Swal.fire({
                                //     text: valid.msg,
                                //     icon: "warning",

                                //     ...swalAlertPopProps,
                                //   });
                                // } else {
                                //   setOpen3(true);
                                // }
                              }}
                            >
                              Save & Share
                            </Button>
                            {/* <Button
                              variant="contained"
                              sx={{ fontSize: "x-small" }}
                              onClick={() => {
                                displayKotList(
                                  fireDb,
                                  userDetail,
                                  tableName,
                                  setKotItem,
                                  setBill,
                                  bill,
                                  setCurrentDataArr,
                                  setOpen2,
                                  Swal
                                );
                                // setOpen2(true);
                              }}
                            >
                              KOT
                            </Button> */}
                          </Grid>{" "}
                        </Grid>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            }
          </Grid>
        </Grid>
      </Grid>
      <DiscountModal
        open={open}
        handleClose={handleClose}
        bill={bill}
        setBill={(data) => {
          setBill((prev) => {
            let prevData = { ...prev };
            prevData.amount = data.amount === NaN ? 0 : data.amount;
            return prevData;
          });
          // setBill({ ...data, amount: data.amount === NaN ? 0 : data.amount });
        }}
        setOpen={setOpen}
      />
      <KotPrint
        open2={open2}
        handleClose2={handleClose2}
        componentRef={componentRefKot}
        kotItem={kotItem}
        tableName={tableName}
        setKOT={() => {
          ApplyAccordingREAndCBUser(
            userDetail,
            () => {
              return setKOT(
                fireDb,
                userDetail,
                tableName,
                setBill,
                bill,
                setCurrentDataArr
              );
            },
            () => {
              return (function () {
                let arr = [...addtoCardProductCBUser];

                arr = arr.map((item) => {
                  return { ...item, printQty: 0 };
                });

                console.log("Add ss", arr);
                setAddtoCardProductCBUser(arr);
              })();
            }
          );
        }}
        setBill={setBill}
        setOpen2={setOpen2}
        addtoCardProductCBUser={addtoCardProductCBUser}
      />
      {/* {open3 && ( */}
      <PrintBilling
        open2={open3}
        handleClose2={handleClose3}
        componentRef={componentRef}
        currentDataArr={currentDataArr}
        tableName={tableName}
        priceWithoutGst={bill?.priceWithoutGst}
        setOpen2={setOpen3}
        addDataCallBack={() => {
          // savePrintBill(
          //   bill,
          //   creditValidtionCheck,
          //   Swal,
          //   userDetail,
          //   saveBill,
          //   saveBillCbUser
          // );
          setAddToCardWindow(false);
          // let valid = creditValidtionCheck(bill);
          // if (valid.status === false) {
          //   Swal.fire({
          //     text: valid.msg,
          //     icon: "warning",
          //     ...swalAlertPopProps,
          //   });
          // } else {
          //   ApplyAccordingREAndCBUser(
          //     userDetail,
          //     () => {
          //       return saveBill();
          //     },
          //     () => {
          //       return saveBillCbUser();
          //     }
          //   );
          // }
        }}
        paybill={bill.paybill}
        userDetail={userDetail}
        discountType={bill.discountType}
        discount={bill.discount}
        totalAmountBill={bill.amount.toFixed(2)}
        discountAmount={discountedAmount(bill)}
        addtoCardProductCBUser={addtoCardProductCBUser}
        bill={bill}
      />
        <PrintBillingPdf
        open2={open3}
        handleClose2={handleClose3}
        componentRef={componentRefPDF}
        currentDataArr={currentDataArr}
        tableName={tableName}
        priceWithoutGst={bill?.priceWithoutGst}
        setOpen2={setOpen3}
        addDataCallBack={() => {
          // savePrintBill(
          //   bill,
          //   creditValidtionCheck,
          //   Swal,
          //   userDetail,
          //   saveBill,
          //   saveBillCbUser
          // );
          setAddToCardWindow(false);
          // let valid = creditValidtionCheck(bill);
          // if (valid.status === false) {
          //   Swal.fire({
          //     text: valid.msg,
          //     icon: "warning",
          //     ...swalAlertPopProps,
          //   });
          // } else {
          //   ApplyAccordingREAndCBUser(
          //     userDetail,
          //     () => {
          //       return saveBill();
          //     },
          //     () => {
          //       return saveBillCbUser();
          //     }
          //   );
          // }
        }}
        paybill={bill.paybill}
        userDetail={userDetail}
        discountType={bill.discountType}
        discount={bill.discount}
        totalAmountBill={bill.amount.toFixed(2)}
        discountAmount={discountedAmount(bill)}
        addtoCardProductCBUser={addtoCardProductCBUser}
        bill={bill}
      />
      {/* )} */}
      <QuantityModal
        setOpen={setOpen4}
        open={open4}
        modalQuantity={modalQuantity}
        setModalQuantity={setModalQuantity}
        sendValue={(val, fcmRowId) => {
          qtySet(
            null,
            val?.qty,
            val?.fcmRowId,
            fireDb,
            userDetail,
            tableName,
            setCurrentDataArr
          );
        }}
        addtoCardProductCBUser={addtoCardProductCBUser}
        setAddtoCardProductCBUser={setAddtoCardProductCBUser}
      />
      <Modal
        open={openRechargeModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            borderRadius: "10px",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography id="modal-modal-description">
            Your subscription period is over!
          </Typography>
          <Typography id="modal-modal-description">
            Please recharge your SMB Account, now!
          </Typography>

          <Box display={"flex"} gap={3} mt={3}>
            <Button onClick={handleCloseModal} variant="contained">
              Cancel
            </Button>
            <Button
              onClick={() => navigate("/smb-account-reacharge")}
              variant="contained"
            >
              Recharge Now
            </Button>
          </Box>
        </Box>
      </Modal>

      <TableD tableData={tableData} open={tableOpen} setOpen={setTableOpen} />
    </Box>
  );
}
